import PropTypes from 'prop-types';
import Media from 'react-media';
import * as detectIt from 'detect-it';
import { maxSmallDimensionPx } from '../galleryStyle';

const query =
  detectIt.primaryInput === 'touch'
    ? `(orientation: portrait) and (max-width: ${maxSmallDimensionPx}px), (orientation: landscape) and (max-height: ${maxSmallDimensionPx}px)`
    : `(max-width: ${maxSmallDimensionPx}px)`;

export function MediaQueryManagement({ children }) {
  return <Media query={query}>{(narrow) => children({ narrow })}</Media>;
}

MediaQueryManagement.propTypes = {
  children: PropTypes.func.isRequired,
};
