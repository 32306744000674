import { Component } from 'react';
import PropTypes from 'prop-types';
import fscreen from 'fscreen';
import { viewNames } from './galleryViewNames';
import { passiveNoCaptureEventOptions } from '../utils/eventListenerOptions';
import {
  scrollToTop,
  getScrollPosition,
  setScrollPosition,
  lockScroll,
  unlockScroll,
} from '../utils/scrollUtils';

export class ScrollManagement extends Component {
  static propTypes = {
    viewName: PropTypes.oneOf(
      Object.keys(viewNames).map((key) => viewNames[key]),
    ).isRequired,
    portfolio: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    inFullscreen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };

  static overlayShownViews = {
    [viewNames.HOMEPAGE_WIDE_INFO]: true,
    [viewNames.PORTFOLIO_WIDE_INFO]: true,
    [viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN]: true,
  };

  static scrollablePortfolioViews = {
    [viewNames.HOMEPAGE_WIDE_IMAGES]: true,
    [viewNames.HOMEPAGE_WIDE_MENU]: true,
    [viewNames.HOMEPAGE_NARROW_IMAGES]: true,
    [viewNames.PORTFOLIO_WIDE_IMAGES]: true,
    [viewNames.PORTFOLIO_WIDE_MENU]: true,
    [viewNames.PORTFOLIO_NARROW_IMAGES]: true,
  };

  static narrowCloseViews = {
    [viewNames.HOMEPAGE_NARROW_MENU]: true,
    [viewNames.HOMEPAGE_NARROW_INFO]: true,
    [viewNames.PORTFOLIO_NARROW_MENU]: true,
    [viewNames.PORTFOLIO_NARROW_INFO]: true,
    [viewNames.PORTFOLIO_NARROW_REQUEST_FULLSCREEN]: true,
  };

  scrollPortfolioId = this.props.portfolio && this.props.portfolio.id;
  scrollPos = getScrollPosition();
  fullscreenScrollPos = this.scrollPos;
  previousScrollPos = this.scrollPos;
  recentScroll = Date.now();
  restoreFullscreenScroll = false;

  componentDidMount() {
    if (ScrollManagement.overlayShownViews[this.props.viewName]) {
      this.scrollPos = lockScroll();
    }
    document.addEventListener(
      'scroll',
      this.handleScroll,
      passiveNoCaptureEventOptions,
    );
    fscreen.addEventListener(
      'fullscreenchange',
      this.handleFsChange,
      passiveNoCaptureEventOptions,
    );
  }

  componentDidUpdate(prevProps) {
    this.restoreFullscreenScroll = false;
    let restoreScroll = false;
    let scrollToTopOfPage = false;
    let lockBackground = false;
    let unlockBackground = false;

    // transition to and from showing overlay
    if (ScrollManagement.overlayShownViews[this.props.viewName]) {
      lockBackground = true;
    } else if (
      ScrollManagement.overlayShownViews[prevProps.viewName] &&
      !ScrollManagement.overlayShownViews[this.props.viewName]
    ) {
      unlockBackground = true;
    }

    // new portfolio page grid -> scroll to top
    if (
      (prevProps.portfolio && prevProps.portfolio.id) !==
        (this.props.portfolio && this.props.portfolio.id) &&
      ScrollManagement.scrollablePortfolioViews[this.props.viewName]
    ) {
      this.scrollPortfolioId = this.props.portfolio && this.props.portfolio.id;
      scrollToTopOfPage = true;
    }

    if (
      prevProps.viewName !== this.props.viewName &&
      ScrollManagement.narrowCloseViews[this.props.viewName]
    ) {
      scrollToTopOfPage = true;
    }

    // change to show portfolio -> restore scroll
    // except if fullscreen change involved, or overlay shown, then handle separately
    if (
      (prevProps.portfolio && prevProps.portfolio.id) ===
        (this.props.portfolio && this.props.portfolio.id) &&
      !ScrollManagement.scrollablePortfolioViews[prevProps.viewName] &&
      ScrollManagement.scrollablePortfolioViews[this.props.viewName] &&
      prevProps.inFullscreen === this.props.inFullscreen &&
      !ScrollManagement.overlayShownViews[prevProps.viewName]
    ) {
      restoreScroll = true;
    }

    // fullscreen change (i.e. same location) -> restore scroll
    if (prevProps.inFullscreen !== this.props.inFullscreen) {
      this.restoreFullscreenScroll = true;
      if (Date.now() - this.recentScroll < 1000) {
        this.fullscreenScrollPos = this.previousScrollPos;
      } else {
        this.fullscreenScrollPos = this.scrollPos;
      }
    }

    /////////////////////////////////////

    if (lockBackground) {
      this.scrollPos = lockScroll();
    }

    if (unlockBackground) {
      unlockScroll(this.scrollPos);
    }

    if (scrollToTopOfPage) {
      scrollToTop();
    }

    if (
      restoreScroll &&
      this.scrollPortfolioId ===
        (this.props.portfolio && this.props.portfolio.id)
    ) {
      setScrollPosition(this.scrollPos);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'scroll',
      this.handleScroll,
      passiveNoCaptureEventOptions,
    );
    fscreen.removeEventListener(
      'fullscreenchange',
      this.handleFsChange,
      passiveNoCaptureEventOptions,
    );
    unlockScroll(0);
  }

  handleFsChange = () => {
    if (
      this.restoreFullscreenScroll &&
      this.scrollPortfolioId ===
        (this.props.portfolio && this.props.portfolio.id)
    ) {
      this.restoreFullscreenScroll = false;
      setScrollPosition(this.fullscreenScrollPos);
    }
  };

  handleScroll = () => {
    // track scroll position for scrollable portfolio views
    if (ScrollManagement.scrollablePortfolioViews[this.props.viewName]) {
      this.scrollPortfolioId = this.props.portfolio && this.props.portfolio.id;
      this.previousScrollPos = this.scrollPos;
      this.scrollPos = getScrollPosition();
      this.recentScroll = Date.now();
    }
  };

  render() {
    return this.props.children;
  }
}
