import {
  stickyFooterHeight,
  stickyFooterPaddingTop,
  stickyFooterPaddingBottom,
  footerNarrowMultiButtonColumnGap,
  footerNarrowMultiButtonRowGap,
} from '../galleryStyle';
import { colorScheme } from '../../colorScheme';
import './Footer.css';

export const FooterSticky = ({ children }) => {
  return (
    <footer className="FooterSticky-container">
      <nav
        className="FooterSticky-sub-container"
        style={{
          height: `${stickyFooterHeight}px`,
          backgroundColor: colorScheme.background,
          paddingTop: `${stickyFooterPaddingTop}px`,
          paddingBottom: `${stickyFooterPaddingBottom}px`,
        }}
      >
        {children}
      </nav>
    </footer>
  );
};

export const FooterNarrowSingleButton = ({ children }) => {
  return (
    <footer className="FooterNarrowSingleButton-container">
      <nav>{children}</nav>
    </footer>
  );
};

export const FooterNarrowMultiButton = ({ children }) => {
  return (
    <footer className="FooterNarrowMultiButton-container">
      <nav
        className="FooterNarrowMultiButton-sub-container"
        style={{
          columnGap: `${footerNarrowMultiButtonColumnGap}px`,
          rowGap: `${footerNarrowMultiButtonRowGap}px`,
        }}
      >
        {children}
      </nav>
    </footer>
  );
};
