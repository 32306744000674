import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

function DataManagement({ children, galleryLoading, user }) {
  return children({ galleryLoading, user });
}

DataManagement.propTypes = {
  galleryLoading: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

const galleryQuery = gql`
  query gallery {
    user(username: "rafael") {
      user {
        id
        username
        displayName
        info {
          id
          title
          isLink
          href
          urlHash
          text
          order
        }
        portfolios {
          id
          title
          urlTitle
          about
          isCoverPortfolio
          order
          coverImage {
            id
            source
            title
            width
            height
          }
          images {
            id
            source
            width
            height
            title
            urlTitle
            location
            otherInfo
            notes
            order
          }
        }
      }
    }
  }
`;

export const DataManagementWithGallery = graphql(galleryQuery, {
  props: ({ data: { loading, user } }) => ({
    galleryLoading: loading,
    user: user ? user.user : null,
  }),
})(DataManagement);
