import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { viewNames } from './galleryViewNames';

export function HeadManagement({
  viewName,
  user,
  portfolio,
  image,
  infoToShow,
  error,
  children,
}) {
  let title;
  switch (viewName) {
    case viewNames.HOMEPAGE_WIDE_IMAGES:
    case viewNames.HOMEPAGE_WIDE_MENU:
    case viewNames.HOMEPAGE_NARROW_IMAGES:
    case viewNames.PORTFOLIO_WIDE_IMAGES:
    case viewNames.PORTFOLIO_WIDE_MENU:
    case viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN:
    case viewNames.PORTFOLIO_NARROW_IMAGES:
    case viewNames.PORTFOLIO_NARROW_REQUEST_FULLSCREEN:
      title = error
        ? `${user.displayName} - ${error.message}`
        : portfolio && portfolio.title
        ? `${user.displayName} / ${portfolio.title}`
        : `${user.displayName} Photography`;
      break;
    case viewNames.HOMEPAGE_WIDE_INFO:
    case viewNames.HOMEPAGE_NARROW_INFO:
    case viewNames.PORTFOLIO_WIDE_INFO:
    case viewNames.PORTFOLIO_NARROW_INFO:
      title = `${user.displayName} - ${infoToShow.title}`;
      break;
    case viewNames.HOMEPAGE_NARROW_MENU:
    case viewNames.PORTFOLIO_NARROW_MENU:
      title = `${user.displayName} - Menu`;
      break;
    case viewNames.IMAGE_PAGE:
      title = error
        ? `${user.displayName} - ${error.message}`
        : `${user.displayName} - ${image.title}`;
      break;
    default:
      title = `${user.displayName} - No View Match`;
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  return children;
}

HeadManagement.propTypes = {
  viewName: PropTypes.oneOf(Object.keys(viewNames).map((key) => viewNames[key]))
    .isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  portfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  infoToShow: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
};
