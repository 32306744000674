import { urlRoot } from '../../shared/cloudinaryUrl';

export function generateImageUrl({
  source,
  width,
  height,
  nativeWidth,
  nativeHeight,
  pixelRatio,
}) {
  const quality = 'q_80';
  const nativeRatio = width / nativeWidth;
  const sharpen =
    nativeRatio < 0.5
      ? 'e_sharpen:50' // OR 'e_unsharp_mask:50'
      : nativeRatio < 0.8
      ? 'e_sharpen:40'
      : nativeRatio !== 1
      ? 'e_sharpen:30'
      : 'e_sharpen:20';
  const imageOptions = [
    'c_scale',
    sharpen,
    `h_${height}`,
    quality,
    `w_${width}`,
  ].join(',');
  return `${urlRoot}/${imageOptions}/${source}${
    pixelRatio ? ` ${pixelRatio}x` : ''
  }`;
}
