import * as React from 'react';
import PropTypes from 'prop-types';
import { Interactive } from 'react-interactive';
import { Link } from 'react-router-dom';
import { interactiveLinkStyle } from '../galleryStyle';
import './InteractiveLink.css';

export const InteractiveLink = React.forwardRef(
  ({ to, href, className, disabled, children, ...props }, ref) => {
    return (
      <Interactive
        as={((href || (to && disabled)) && 'a') || (to && Link) || 'button'}
        to={disabled ? undefined : to}
        href={disabled ? undefined : href}
        className={`InteractiveLink${className ? ` ${className}` : ''}`}
        disabled={disabled}
        {...interactiveLinkStyle}
        {...props}
        ref={ref}
      >
        {children}
      </Interactive>
    );
  },
);

InteractiveLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
