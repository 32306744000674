import PropTypes from 'prop-types';
import { FooterNarrowSingleButton } from '../footer/Footer';
import { LeftArrowIconButton } from '../ui/IconButtons';
import { InteractiveLink } from '../ui/InteractiveLink';
import { Simplemark } from '../../shared/Simplemark';
import {
  narrowScrollPageBottomSpace,
  menuPageItemTitleFontSizeRem,
} from '../galleryStyle';
import './MenuPage.css';

export const MenuPageContainer = ({ children }) => (
  <div
    className="MenuPage-container"
    style={{ paddingBottom: narrowScrollPageBottomSpace }}
  >
    {children}
  </div>
);

export const RequestFullscreenContainer = ({ children }) => (
  <div className="MenuPage-request-fullscreen-container">{children}</div>
);

export const MenuPageFooter = ({ handleClose, title }) => {
  return (
    <FooterNarrowSingleButton>
      <LeftArrowIconButton onClick={handleClose} title={title} />
    </FooterNarrowSingleButton>
  );
};

MenuPageFooter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const MenuPageItems = ({ info, infoToShow = {} }) => {
  return (
    <ul className="MenuPage-items-container">
      {info.map((info) => (
        <li key={info.id} className="MenuPage-item">
          {info.isLink ? (
            <InteractiveLink
              href={info.href}
              className="MenuPage-item-title"
              style={{ fontSize: `${menuPageItemTitleFontSizeRem}rem` }}
              target={
                ((!/mailto:/.test(info.href) && !/tel:/.test(info.href)) ||
                  null) &&
                '_blank'
              }
              rel="noopener noreferrer"
            >
              {info.title}
            </InteractiveLink>
          ) : (
            <MenuPageItem
              title={info.title}
              content={info.text}
              urlHash={info.urlHash}
              isShown={infoToShow.urlHash === info.urlHash}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

MenuPageItems.propTypes = {
  info: PropTypes.array.isRequired,
  infoToShow: PropTypes.object,
};

const MenuPageItem = ({ title, content, urlHash, isShown }) => {
  return (
    <span>
      <InteractiveLink
        to={`#${isShown ? 'menu' : urlHash}`}
        className={`MenuPage-item-title${
          isShown ? ' MenuPage-item-title-shown' : ''
        }`}
        style={{ fontSize: `${menuPageItemTitleFontSizeRem}rem` }}
      >
        {title}
      </InteractiveLink>
      {isShown && (
        <Simplemark className="MenuPage-item-content">{content}</Simplemark>
      )}
    </span>
  );
};

MenuPageItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  urlHash: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired,
};
