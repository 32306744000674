import { Component } from 'react';
import PropTypes from 'prop-types';

export class CodeSplit extends Component {
  static propTypes = {
    module: PropTypes.instanceOf(Promise).isRequired,
    componentWhileLoading: PropTypes.func,
    componentOnError: PropTypes.func,
    componentProps: PropTypes.object,
  };

  static defaultProps = {
    componentWhileLoading: null,
    componentOnError: null,
    componentProps: {},
  };

  state = {
    Component: null,
    error: false,
  };

  render() {
    if (!this.state.Component) {
      this.props.module
        .then((module) => {
          const { UserWithDataAndDragAndDropContext } = module;
          this.setState({ Component: UserWithDataAndDragAndDropContext });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }

    const { componentProps } = this.props;
    const { Component } = this.state;

    return Component !== null ? (
      <Component {...componentProps} />
    ) : this.state.error !== false ? (
      this.props.componentOnError !== null ? (
        <this.props.componentOnError error={this.state.error} />
      ) : null
    ) : this.props.componentWhileLoading !== null ? (
      <this.props.componentWhileLoading />
    ) : null;
  }
}
