import PropTypes from 'prop-types';
import { ImageLoadingAndError } from './ImageLoadingAndError';
import './ImageFit100PercentBox.css';

export function ImageFit100PercentBox({
  imageTitle,
  src,
  srcSet,
  imageLoaded,
  imageError,
}) {
  return imageLoaded ? (
    <img
      alt={imageTitle}
      src={src}
      srcSet={srcSet}
      className="ImageFit100PercentBox-image"
    />
  ) : (
    <ImageLoadingAndError imageTitle={imageTitle} imageError={imageError} />
  );
}

ImageFit100PercentBox.propTypes = {
  imageTitle: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  imageLoaded: PropTypes.bool.isRequired,
  imageError: PropTypes.bool.isRequired,
};
