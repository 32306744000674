import { DataManagementWithGallery } from './galleryManagement/DataManagement';
import { MediaQueryManagement } from './galleryManagement/MediaQueryManagement';
import { RouteManagement } from './galleryManagement/RouteManagement';
import { FullscreenManagement } from './galleryManagement/FullscreenManagement';
import { ViewManagement } from './galleryManagement/ViewManagement';
import { ScrollManagement } from './galleryManagement/ScrollManagement';
import { HeadManagement } from './galleryManagement/HeadManagement';
import { PreloadImageManagement } from './galleryManagement/PreloadImageManagement';

export function Gallery() {
  return (
    <DataManagementWithGallery>
      {({ galleryLoading, user }) => (
        <MediaQueryManagement>
          {({ narrow }) => (
            <RouteManagement>
              {({ routeName, router }) => (
                <FullscreenManagement router={router}>
                  {({ fullscreen, inFullscreen }) => (
                    <ViewManagement
                      galleryLoading={galleryLoading}
                      user={user}
                      narrow={narrow}
                      routeName={routeName}
                      router={router}
                      fullscreen={fullscreen}
                      inFullscreen={inFullscreen}
                    >
                      {({ view, render }) => (
                        // below here EVERY prop is from view
                        <ScrollManagement
                          viewName={view.viewName}
                          portfolio={view.portfolio}
                          inFullscreen={view.inFullscreen}
                        >
                          <HeadManagement
                            viewName={view.viewName}
                            user={view.user}
                            portfolio={view.portfolio}
                            image={view.image}
                            infoToShow={view.infoToShow}
                            error={view.error}
                          >
                            <PreloadImageManagement
                              viewName={view.viewName}
                              narrow={view.narrow}
                              user={view.user}
                              portfolioCoverImages={view.portfolioCoverImages}
                              portfolio={view.portfolio}
                              image={view.image}
                            >
                              {({ onComplete }) => render({ onComplete })}
                            </PreloadImageManagement>
                          </HeadManagement>
                        </ScrollManagement>
                      )}
                    </ViewManagement>
                  )}
                </FullscreenManagement>
              )}
            </RouteManagement>
          )}
        </MediaQueryManagement>
      )}
    </DataManagementWithGallery>
  );
}
