import { FooterNarrowSingleButton } from '../footer/Footer';
import { MenuIconButton } from '../ui/IconButtons';
import { narrowScrollPageBottomSpace } from '../galleryStyle';
import './HomePage.css';

export const HomePageNarrowContainer = ({ children }) => (
  <div
    className="HomePageNarrow-container"
    style={{ paddingBottom: narrowScrollPageBottomSpace }}
  >
    {children}
  </div>
);

export const HomePageWideContainer = ({ children }) => (
  <div className="HomePageWide-container">{children}</div>
);

export const HomePageNarrowFooter = () => {
  return (
    <FooterNarrowSingleButton>
      <MenuIconButton to="/#menu" />
    </FooterNarrowSingleButton>
  );
};
