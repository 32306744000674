import PropTypes from 'prop-types';
import { FooterSticky, FooterNarrowMultiButton } from '../footer/Footer';
import {
  HomeIconButton,
  LeftArrowIconButton,
  RightArrowIconButton,
  EnterFullscreenIconButton,
  ExitFullscreenIconButton,
  MenuIconButton,
} from '../ui/IconButtons';
import {
  narrowScrollPageBottomSpace,
  stickyFooterCenterButtonMargin,
  stickyFooterHomeButtonMargin,
  stickyFooterFullscreenButtonMargin,
  isIPad,
} from '../galleryStyle';
import './PortfolioPage.css';

export const PortfolioPageNarrowContainer = ({ children }) => (
  <div
    className="PortfolioPageNarrow-container"
    style={{ paddingBottom: narrowScrollPageBottomSpace }}
  >
    {children}
  </div>
);

export const PortfolioPageWideContainer = ({ children }) => (
  <div className="PortfolioPageWide-container">{children}</div>
);

export const PortfolioPageNarrowFooter = ({
  portfolio,
  prevPortfolio,
  nextPortfolio,
}) => {
  return (
    <FooterNarrowMultiButton>
      <LeftArrowIconButton
        to={prevPortfolio && `/${prevPortfolio.urlTitle}`}
        disabled={!prevPortfolio}
        title={prevPortfolio && `Previous Portfolio: ${prevPortfolio.title}`}
      />
      <RightArrowIconButton
        to={nextPortfolio && `/${nextPortfolio.urlTitle}`}
        disabled={!nextPortfolio}
        title={nextPortfolio && `Next Portfolio: ${nextPortfolio.title}`}
      />
      <HomeIconButton />
      <MenuIconButton to={`/${portfolio ? portfolio.urlTitle : ''}#menu`} />
    </FooterNarrowMultiButton>
  );
};

PortfolioPageNarrowFooter.propTypes = {
  portfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  prevPortfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  nextPortfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
};

export const PortfolioPageWideFooter = ({
  prevPortfolio,
  nextPortfolio,
  inFullscreen,
  fullscreen,
}) => {
  return (
    <FooterSticky>
      <HomeIconButton
        style={{ marginLeft: `${stickyFooterHomeButtonMargin}px` }}
      />
      <LeftArrowIconButton
        to={prevPortfolio && `/${prevPortfolio.urlTitle}`}
        disabled={!prevPortfolio}
        title={prevPortfolio && `Previous Portfolio: ${prevPortfolio.title}`}
        style={{ marginLeft: `-${stickyFooterCenterButtonMargin}px` }}
      />
      <RightArrowIconButton
        to={nextPortfolio && `/${nextPortfolio.urlTitle}`}
        disabled={!nextPortfolio}
        title={nextPortfolio && `Next Portfolio: ${nextPortfolio.title}`}
        style={{ marginLeft: `${stickyFooterCenterButtonMargin}px` }}
      />
      {inFullscreen ? (
        <ExitFullscreenIconButton
          disabled={!fullscreen.fullscreenEnabled || isIPad}
          onClick={fullscreen.exitFullscreen}
          style={{ marginLeft: `${stickyFooterFullscreenButtonMargin}px` }}
        />
      ) : (
        <EnterFullscreenIconButton
          disabled={!fullscreen.fullscreenEnabled || isIPad}
          onClick={fullscreen.enterFullscreen}
          style={{ marginLeft: `${stickyFooterFullscreenButtonMargin}px` }}
        />
      )}
    </FooterSticky>
  );
};

PortfolioPageWideFooter.propTypes = {
  prevPortfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  nextPortfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  inFullscreen: PropTypes.bool,
  fullscreen: PropTypes.shape({
    enterFullscreen: PropTypes.func.isRequired,
    exitFullscreen: PropTypes.func.isRequired,
    fullscreenEnabled: PropTypes.bool.isRequired,
  }),
};
