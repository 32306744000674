export const colorScheme = {
  background: 'rgb(26, 26, 26)',
  imagePageOverlayBackground: 'rgba(0, 0, 0, 0.85)',
  normalText: 'rgb(160, 160, 160)',
  svgFill: 'rgb(160, 160, 160)',
  interactedColor: 'rgb(224, 224, 224)',
  focusOutlineColor: 'rgb(224, 224, 224)',
};

export const overlayColorScheme = {
  overlayText: colorScheme.normalText,
  overlayPageBackground: 'rgba(26, 26, 26, 0.4)',
  overlayContentBackground: colorScheme.background,
  overlayBorder: 'rgb(108, 108, 108)',
};
