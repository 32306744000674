import * as React from 'react';
import PropTypes from 'prop-types';
import ReactSimplemark from 'react-simplemark';
import { InteractiveLink } from '../gallery/ui/InteractiveLink';
import './Simplemark.css';

const customRenderer = {
  Link(props) {
    return (
      <InteractiveLink {...props} target="_blank" rel="noopener noreferrer" />
    );
  },
};

export const Simplemark = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <ReactSimplemark
      renderer={customRenderer}
      {...props}
      className={`Simplemark${className ? ` ${className}` : ''}`}
      ref={ref}
    />
  );
});

Simplemark.displayName = 'Simplemark';

Simplemark.propTypes = {
  className: PropTypes.string,
};
