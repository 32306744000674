import * as detectIt from 'detect-it';

export const passiveNoCaptureEventOptions = detectIt.supportsPassiveEvents
  ? { passive: true, capture: false }
  : false;

export const passiveCaptureEventOptions = detectIt.supportsPassiveEvents
  ? { passive: true, capture: true }
  : true;

export const noPassiveNoCaptureEventOptions = detectIt.supportsPassiveEvents
  ? { passive: false, capture: false }
  : false;

export const noPassiveCaptureEventOptions = detectIt.supportsPassiveEvents
  ? { passive: false, capture: true }
  : true;
