import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Interactive } from 'react-interactive';
import { generateImageUrl } from './generateImageUrl';
import { loadImage, loadedImages } from './loadImage';
import { calcImageSize } from './calcImageSize';
import { imageFocusFromKeyStyle } from '../galleryStyle';

export class ImageManagement extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    image: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    sizeConstraints: PropTypes.shape({
      maxWidth: PropTypes.number,
      maxHeight: PropTypes.number,
      longSide: PropTypes.number,
      shortSide: PropTypes.number,
    }).isRequired,
    onComplete: PropTypes.func,
    asLink: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    onStateChange: PropTypes.func,
  };
  static defaultProps = {
    onComplete: null,
    asLink: false,
    to: null,
    onClick: null,
    onStateChange: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.image.id !== state.imageId) {
      const { image, sizeConstraints } = props;
      const imageSize = calcImageSize(image, sizeConstraints);
      const src = generateImageUrl({
        source: image.source,
        width: imageSize.width,
        height: imageSize.height,
        nativeWidth: image.width,
        nativeHeight: image.height,
      });
      const srcSet = generateImageUrl({
        source: image.source,
        width: imageSize.scaledWidth,
        height: imageSize.scaledHeight,
        nativeWidth: image.width,
        nativeHeight: image.height,
        pixelRatio: imageSize.pixelRatio,
      });

      return {
        imageId: props.image.id,
        imageSize,
        src,
        srcSet,
        imageLoaded: Boolean(loadedImages[src] && loadedImages[srcSet]),
      };
    }

    return null;
  }

  state = {
    imageId: null,
    imageSize: {},
    src: '',
    srcSet: '',
    imageLoaded: false,
    imageError: false,
  };

  requestedImages = {};
  cancelImageLoad = {};

  componentDidMount() {
    if (this.state.imageLoaded) {
      this.props.onComplete && this.props.onComplete(this.props.image.id);
    } else {
      this.loadImage();
    }
  }

  componentDidUpdate() {
    if (!this.state.imageLoaded && !this.state.imageError) {
      this.loadImage();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.imageLoaded !== nextState.imageLoaded ||
      this.state.imageError !== nextState.imageError ||
      this.props.image.id !== nextProps.image.id
    );
  }

  componentWillUnmount() {
    Object.keys(this.cancelImageLoad).forEach((key) => {
      this.cancelImageLoad[key]();
    });
  }

  loadImage() {
    const { src, srcSet } = this.state;
    if (this.requestedImages[src] && this.requestedImages[srcSet]) return;
    this.requestedImages[src] = true;
    this.requestedImages[srcSet] = true;
    this.cancelImageLoad[`${src}${srcSet}`] = loadImage({
      src,
      srcset: srcSet,
      onComplete: (e) => {
        this.setState(
          {
            imageLoaded: e.type === 'load',
            imageError: e.type !== 'load',
          },
          this.props.onComplete &&
            this.props.onComplete.bind(null, this.props.image.id),
        );
        delete this.requestedImages[src];
        delete this.requestedImages[srcSet];
        delete this.cancelImageLoad[`${src}${srcSet}`];
      },
    });
  }

  handleStateChange = (states) => {
    if (this.props.onStateChange) {
      this.props.onStateChange({
        id: this.props.image.id,
        ...states,
      });
    }
  };

  render() {
    const { asLink, to, children } = this.props;
    const { src, srcSet, imageSize } = this.state;
    const { width: imageWidth, height: imageHeight } = imageSize;
    const { imageLoaded, imageError } = this.state;

    const renderLink = asLink && (imageLoaded || imageError);

    const LinkComponent = renderLink ? Interactive : null;
    const linkComponentProps = renderLink
      ? {
          as: Link,
          to,
          focusFromKeyStyle: imageFocusFromKeyStyle,
          onClick: this.props.onClick,
          onStateChange: this.handleStateChange,
        }
      : null;

    return children({
      src,
      srcSet,
      imageWidth,
      imageHeight,
      imageLoaded,
      imageError,
      LinkComponent,
      linkComponentProps,
    });
  }
}
