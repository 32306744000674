import { Component } from 'react';
import PropTypes from 'prop-types';
import { InteractiveLink } from '../ui/InteractiveLink';
import { PageNotFound } from '../loadingAndError/PageNotFound';
import { ImageManagement } from './ImageManagement';
import { ImageFit100PercentWidthAsLongDimension } from './ImageFit100PercentWidthAsLongDimension';
import { stickyFooterHeight, imageGridTitleFontSizeRem } from '../galleryStyle';
import { colorScheme } from '../../colorScheme';

import './ImageGrid.css';

export class ImageGrid extends Component {
  static propTypes = {
    images: PropTypes.array,
    imagesId: PropTypes.string,
    toLinkBase: PropTypes.string,
    showTitle: PropTypes.bool,
    onClick: PropTypes.func,
    onComplete: PropTypes.func,
    error: PropTypes.object,
    columns: PropTypes.number.isRequired, // number of columns of images
    maxImageSize: PropTypes.number.isRequired, // max long dimension of each image
    sideSpace: PropTypes.number, // minimum space on the left and right side of the image grid
    topSpace: PropTypes.number, // minimum space above the image grid
    bottomSpace: PropTypes.number, // minimum space below the image grid
    rowSpace: PropTypes.number, // space between each row
    maxColumnSpace: PropTypes.number, // max space between columns
    minColumnSpace: PropTypes.number, // min space between columns
    maxColumnSpaceNoConsecutiveHorizontalImages: PropTypes.number, // max space between columns when there are no consecutive horizontal images in every row
    minColumnSpaceNoConsecutiveHorizontalImages: PropTypes.number, // min space between columns when there are no consecutive horizontal images in every row
    minHorizontalImageWidthHeightRatio: PropTypes.number, // width / height ratio that defines a horizontal image, i.e. anything more horizontal then this ratio is considered a horizontal image
  };

  static defaultProps = {
    images: null,
    imagesId: null,
    toLinkBase: '',
    showTitle: false,
    onClick: null,
    onComplete: null,
    error: null,
    sideSpace: 0,
    topSpace: 0,
    bottomSpace: 0,
    rowSpace: 0,
    maxColumnSpace: 0,
    minColumnSpace: 0,
    maxColumnSpaceNoConsecutiveHorizontalImages: 0,
    minColumnSpaceNoConsecutiveHorizontalImages: 0,
    minHorizontalImageWidthHeightRatio: 4 / 5,
  };

  state = { activeHoverImage: {}, activeFocusImage: {} };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.props.imagesId ||
      this.props.imagesId !== nextProps.imagesId ||
      this.state.activeHoverImage !== nextState.activeHoverImage ||
      this.state.activeFocusImage !== nextState.activeFocusImage
    );
  }

  calculateGridSpacing() {
    const {
      images,
      maxImageSize,
      columns,
      sideSpace,
      topSpace,
      bottomSpace,
      maxColumnSpace,
      minColumnSpace,
      maxColumnSpaceNoConsecutiveHorizontalImages,
      minColumnSpaceNoConsecutiveHorizontalImages,
      minHorizontalImageWidthHeightRatio,
    } = this.props;

    if (!images) {
      return {};
    }

    // reduce column space when there are no consecutive horizontal images in a row
    const adjustColumnSpace =
      columns > 1 &&
      images.every((image, idx, arr) => {
        // if image is at the end of a row, then don't compare it to next image
        if ((idx + 1) % columns === 0 || idx === arr.length - 1) return true;

        // check if this image and the next image are both horizontal
        if (
          image.width / image.height >= minHorizontalImageWidthHeightRatio &&
          arr[idx + 1].width / arr[idx + 1].height >=
            minHorizontalImageWidthHeightRatio
        )
          return false;
        return true;
      });

    const maxColumnSpaceAdj = adjustColumnSpace
      ? maxColumnSpaceNoConsecutiveHorizontalImages
      : maxColumnSpace;
    const minColumnSpaceAdj = adjustColumnSpace
      ? minColumnSpaceNoConsecutiveHorizontalImages
      : minColumnSpace;

    const gridContainerStyle = {
      // Note that maxColumnSpaceAdj is provided for by increasing the width of
      // each image-container div and then centering the image-sub-container
      // horizontally inside the image-container (the image-container width
      // is grid-container width / columns). This extra space when
      // added to minColumnSpaceAdj (which is the margin of the image-container)
      // equals the maxColumnSpaceAdj. So the extra space added to each column is
      // maxColumnSpaceAdj - minColumnSpaceAdj, however, 1/2 of this extra space is
      // also added to the end of each row, **so need to add**
      // maxColumnSpaceAdj - minColumnSpaceAdj to the grid-container max width to
      // allow enough space to get maxColumnSpaceAdj between each column.
      // Also note that because of this, when reducing the width of the page,
      // the space outside the columns and between the columns reduces
      // together (which is good), until the space between the
      // columns equals minColumnSpaceAdj.
      maxWidth: `${
        maxImageSize * columns +
        maxColumnSpaceAdj * (columns - 1) +
        (columns > 1 ? maxColumnSpaceAdj - minColumnSpaceAdj : 0) +
        sideSpace * 2
      }px`,

      padding: `${topSpace}px ${sideSpace}px ${bottomSpace}px`,
    };

    const imageContainerWidth = `calc((100% / ${columns}) - ${
      ((columns - 1) * minColumnSpaceAdj) / columns
    }px)`;
    let imageContainerMaxWidth = null;

    // Adjustments for 0 sideSpace so that image-container div is the same size
    // image-sub-container and maxColumnSpaceAdj is provided for by space between
    // the image-container divs. This prevents extra space on the end of each row
    // from the floating of the image-sub-container inside of the image-container.
    if (sideSpace === 0 && columns > 1) {
      imageContainerMaxWidth = `${maxImageSize}px`;
      gridContainerStyle.justifyContent = 'space-between';
      gridContainerStyle.maxWidth = `${
        maxImageSize * columns +
        maxColumnSpaceAdj * (columns - 1) +
        sideSpace * 2
      }px`;
    }

    const sizeConstraints = {
      longSide: maxImageSize,
    };

    return {
      gridContainerStyle,
      minColumnSpaceAdj,
      imageContainerWidth,
      imageContainerMaxWidth,
      sizeConstraints,
    };
  }

  handleStateChange = ({ id, prevState, state }) => {
    const newState = {};
    if (prevState.hover !== state.hover || prevState.active !== state.active) {
      newState.activeHoverImage = {
        [id]: Boolean(state.hover || state.active),
      };
    }
    if (prevState.focus !== state.focus) {
      newState.activeFocusImage = { [id]: state.focus === 'focusFromKey' };
    }
    this.setState(newState);
  };

  handleImageComplete = (imageId) => {
    // note don't have to worry about this getting called after ImageGrid unmounts
    // because Image cancels onComplete callback when it unmounts
    delete this.gridImages[imageId];
    if (Object.keys(this.gridImages).length === 0)
      this.props.onComplete && this.props.onComplete();
  };

  render() {
    const { images, onComplete, error } = this.props;

    if (!images || images.length === 0) {
      onComplete && onComplete();
      const notFoundMessage = !images ? error : { message: 'No Images' };
      return (
        <PageNotFound
          {...notFoundMessage}
          style={{ flexGrow: '1', paddingBottom: `${stickyFooterHeight}px` }}
        />
      );
    }

    const { maxImageSize, columns, rowSpace, toLinkBase, onClick, showTitle } =
      this.props;

    const {
      gridContainerStyle,
      minColumnSpaceAdj,
      imageContainerWidth,
      imageContainerMaxWidth,
      sizeConstraints,
    } = this.calculateGridSpacing();

    // object to keep track of images rendered in grid for onComplete callback
    this.gridImages = {};

    return (
      <div className="ImageGrid-container" style={gridContainerStyle}>
        {images.map((image, index) => {
          const marginTop = index < columns ? '0' : `${rowSpace}px`;
          const marginLeft =
            index % columns === 0 ? '0' : `${minColumnSpaceAdj / 2}px`;
          const marginRight =
            (index + 1) % columns === 0 ? '0' : `${minColumnSpaceAdj / 2}px`;

          const imageContainerStyle = {
            margin: `${marginTop} ${marginRight} 0 ${marginLeft}`,
            width: imageContainerWidth,
            maxWidth: imageContainerMaxWidth,
          };

          if (!image.source || !image.id)
            // this comes into play when showing all portfolios
            // and a portfolio doesn't have a cover image
            return (
              <div
                key={`no-image-${index}`}
                className="ImageGrid-no-image"
                style={imageContainerStyle}
              >
                No Image
              </div>
            );

          this.gridImages[image.id] = true;
          return (
            <div
              // add imagesId to key so the same image that is part of two
              // different image arrays (e.g. a portfolio cover image)
              // is not treated as the same component by react (which causes
              // the click through bug, clicking on the cover image shows the
              // portfolio and when the same image is below it, its onClick
              // handler is also called)
              key={`${image.id}-${this.props.imagesId}`}
              style={imageContainerStyle}
            >
              <div
                className="ImageGrid-image-sub-container"
                style={{ maxWidth: `${maxImageSize}px` }}
              >
                <ImageManagement
                  image={image}
                  sizeConstraints={sizeConstraints}
                  onComplete={this.handleImageComplete}
                  asLink
                  to={`${toLinkBase}/${image.urlTitle}`}
                  onClick={onClick}
                  onStateChange={showTitle ? this.handleStateChange : null}
                >
                  {({
                    src,
                    srcSet,
                    imageWidth,
                    imageHeight,
                    imageLoaded,
                    imageError,
                    LinkComponent,
                    linkComponentProps,
                  }) => (
                    <ImageFit100PercentWidthAsLongDimension
                      imageTitle={image.title}
                      src={src}
                      srcSet={srcSet}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      imageLoaded={imageLoaded}
                      imageError={imageError}
                      LinkComponent={LinkComponent}
                      linkComponentProps={linkComponentProps}
                    />
                  )}
                </ImageManagement>
                {showTitle && (
                  <div className="ImageGrid-image-title-container">
                    <InteractiveLink
                      to={`${toLinkBase}/${image.urlTitle}`}
                      tabIndex="-1"
                      className="ImageGrid-image-title"
                      style={{
                        color:
                          this.state.activeHoverImage[image.id] ||
                          this.state.activeFocusImage[image.id]
                            ? colorScheme.interactedColor
                            : colorScheme.normalText,
                        fontSize: `${imageGridTitleFontSizeRem}rem`,
                      }}
                    >
                      {image.title}
                    </InteractiveLink>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
