import * as React from 'react';
import PropTypes from 'prop-types';
import { Interactive, setEventFrom } from 'react-interactive';
import { requestFullscreenButtonFontSizeRem } from '../galleryStyle';
import { colorScheme } from '../../colorScheme';
import './RequestFullscreenButton.css';

export function RequestFullscreenButton({ enterFullscreen }) {
  const callbackRef = React.useCallback((node) => {
    if (node) {
      setEventFrom('key');
      node.focus();
    }
  }, []);

  return (
    <div className="RequestFullscreenButton-container">
      <Interactive
        as="button"
        className="RequestFullscreenButton-button"
        onClick={enterFullscreen}
        ref={callbackRef}
        style={{
          fontSize: `${requestFullscreenButtonFontSizeRem}rem`,
        }}
        hoverStyle={{
          color: colorScheme.interactedColor,
          borderColor: colorScheme.interactedColor,
        }}
        activeStyle={{
          color: colorScheme.interactedColor,
          borderColor: colorScheme.interactedColor,
        }}
        focusFromKeyStyle={{
          outline: `3px dotted ${colorScheme.focusOutlineColor}`,
          outlineOffset: '2px',
        }}
      >
        Enter Fullscreen
      </Interactive>
      <div className="RequestFullscreenButton-description">
        Fullscreen mode is required to view an image.
      </div>
    </div>
  );
}

RequestFullscreenButton.propTypes = {
  enterFullscreen: PropTypes.func.isRequired,
};
