export function calcImageSize(
  image,
  { maxWidth, maxHeight, longSide, shortSide },
) {
  let width = 0;
  let height = 0;

  const setWidth = (newWidth) => {
    width = Math.round(newWidth);
    height = Math.round(width * (image.height / image.width));
  };
  const setHeight = (newHeight) => {
    height = Math.round(newHeight);
    width = Math.round(height * (image.width / image.height));
  };

  if (maxHeight && maxWidth) {
    if (maxWidth / maxHeight > image.width / image.height) setHeight(maxHeight);
    else setWidth(maxWidth);
  } else if (maxHeight) setHeight(maxHeight);
  else if (maxWidth) setWidth(maxWidth);
  else if (longSide && shortSide) {
    if (
      shortSide / longSide >
      Math.max(image.height, image.width) / Math.min(image.height, image.width)
    ) {
      if (image.width > image.height) setWidth(longSide);
      else setHeight(longSide);
    } else if (image.width < image.height) setWidth(shortSide);
    else setHeight(shortSide);
  } else if (longSide) {
    if (image.width > image.height) setWidth(longSide);
    else setHeight(longSide);
  } else if (shortSide) {
    if (image.width < image.height) setWidth(shortSide);
    else setHeight(shortSide);
  }

  // don't request css-pixel size image greater than half the original image size,
  // most applicable for low res screens
  if (width > image.width / 2 || height > image.height / 2) {
    width = Math.round(image.width / 2);
    height = Math.round(image.height / 2);
  }

  let pixelRatio = window.devicePixelRatio || 1;

  let scaledWidth = Math.round(width * pixelRatio);
  let scaledHeight = Math.round(height * pixelRatio);

  // For super hi res screens, adjust pixel ratio down if needed
  // so don't return super small width and height just so scaledWidth/height is pixel perfect
  // e.g. if pixelRatio is 4, width is 1000, image.width is 3000, and scaledWidth is 4000
  // width will stay at 1000, scaledWidth will be 3000 and pixelRatio will be 3
  // e.g. if pixelRatio is 4, width is 2000, image.width is 3000, and scaledWidth is 8000
  // width will be set to 1500, scaledWidth will be 3000 and pixelRatio will be 2
  const maxAdjustedPixelRatio = 2;

  // so don't request image larger than original
  if (scaledWidth > image.width || scaledHeight > image.height) {
    scaledWidth = image.width;
    scaledHeight = image.height;
    if (pixelRatio > maxAdjustedPixelRatio) {
      pixelRatio = Math.max(scaledWidth / width, maxAdjustedPixelRatio);
    }
    if (
      width > scaledWidth / maxAdjustedPixelRatio ||
      height > scaledHeight / maxAdjustedPixelRatio
    ) {
      width = Math.round(scaledWidth / pixelRatio);
      height = Math.round(scaledHeight / pixelRatio);
    }
  }

  return {
    width,
    height,
    scaledWidth,
    scaledHeight,
    pixelRatio,
  };
}
