import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading } from './Loading';
import { logError } from '../../shared/logError';

export class Error extends PureComponent {
  static propTypes = {
    error: PropTypes.string.isRequired,
  };

  componentDidMount() {
    logError(new Error(this.props.error));
  }

  render() {
    return <Loading>Sorry, there was an error!</Loading>;
  }
}
