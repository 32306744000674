import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading } from './Loading';
import { InteractiveLink } from '../ui/InteractiveLink';
import { pageNotFound } from './errorMessages';
import { logError } from '../../shared/logError';

export class PageNotFound extends PureComponent {
  static propTypes = {
    message: PropTypes.string,
    linkText: PropTypes.string,
    linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    style: PropTypes.object,
  };

  static defaultProps = {
    message: pageNotFound.message,
    linkText: pageNotFound.linkText,
    linkTo: pageNotFound.linkTo,
    style: null,
  };

  componentDidMount() {
    const { message, linkText, linkTo } = this.props;
    logError(
      new Error(
        `Page Not Found! Message: ${message}, LinkText: ${linkText} LinkTo: ${linkTo}`,
      ),
    );
  }

  render() {
    const { message, linkText, linkTo, style } = this.props;
    const to = typeof linkTo === 'string' ? linkTo : null;
    const onClick = typeof linkTo === 'function' ? linkTo : null;
    return (
      <Loading style={style}>
        {message}
        {' - '}
        <InteractiveLink to={to} onClick={onClick}>
          {linkText}
        </InteractiveLink>
      </Loading>
    );
  }
}
