import { Component } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';
import { InteractiveLink } from '../ui/InteractiveLink';
import { passiveNoCaptureEventOptions } from '../utils/eventListenerOptions';
import { colorScheme } from '../../colorScheme';

import './InfoMenu.css';

export class InfoMenu extends Component {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    info: PropTypes.array.isRequired,
    currentPath: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  componentDidMount() {
    document.addEventListener(
      'keydown',
      this.handleKeyDown,
      passiveNoCaptureEventOptions,
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'keydown',
      this.handleKeyDown,
      passiveNoCaptureEventOptions,
    );
  }

  clickOutsideClose = (e) => {
    if (this.props.isShown) {
      this.props.handleClose();
    }
  };

  handleKeyDown = (e) => {
    if (
      (e.key === 'm' && this.menuTitleElement) ||
      (e.key === 'Escape' && this.props.isShown)
    ) {
      this.menuTitleElement.click();
    }
  };

  handleTitleRef = (el) => {
    this.menuTitleElement = el;
  };

  render() {
    const { currentPath, info, isShown, disabled } = this.props;

    return (
      <ClickOutside
        className="InfoMenu-container"
        onClickOutside={this.clickOutsideClose}
      >
        <InteractiveLink
          to={isShown ? currentPath : `${currentPath}#menu`}
          className="InfoMenu-menu-link"
          ref={this.handleTitleRef}
          disabled={disabled}
        >
          Additional Information
        </InteractiveLink>
        {isShown && (
          <div className="InfoMenu-items-container">
            <ul
              className="InfoMenu-items-ul"
              style={{ backgroundColor: colorScheme.background }}
            >
              {info.map((infoItem) => (
                <li key={infoItem.id} className="InfoMenu-item">
                  {infoItem.isLink ? (
                    <InteractiveLink
                      href={infoItem.href}
                      target={
                        ((!/mailto:/.test(infoItem.href) &&
                          !/tel:/.test(infoItem.href)) ||
                          null) &&
                        '_blank'
                      }
                      rel="noopener noreferrer"
                    >
                      {infoItem.title}
                    </InteractiveLink>
                  ) : (
                    <InteractiveLink to={`${currentPath}#${infoItem.urlHash}`}>
                      {infoItem.title}
                    </InteractiveLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </ClickOutside>
    );
  }
}
