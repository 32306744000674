import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  HomePageNarrowContainer,
  HomePageWideContainer,
  HomePageNarrowFooter,
} from '../homePage/HomePage';
import {
  PortfolioPageNarrowContainer,
  PortfolioPageWideContainer,
  PortfolioPageWideFooter,
  PortfolioPageNarrowFooter,
} from '../portfolioPage/PortfolioPage';
import { ImagePage } from '../imagePage/ImagePage';
import {
  MenuPageContainer,
  MenuPageItems,
  MenuPageFooter,
  RequestFullscreenContainer,
} from '../menuPage/MenuPage';
import { NarrowHeader, WideHeader } from '../header/Header';
import { InfoOverlay, RequestFullscreenOverlay } from '../overlay/Overlay';
import { ImageGrid } from '../image/ImageGrid';
import {
  narrowHomePageGridSpacing,
  narrowPortfolioPageGridSpacing,
  wideHomePageGridSpacing,
  widePortfolioPageGridSpacing,
} from '../galleryStyle';
import { WheelEventNavigation } from '../utils/WheelEventNavigation';
import { RequestFullscreenButton } from '../ui/RequestFullscreenButton';
import { Loading } from '../loadingAndError/Loading';
import { Error } from '../loadingAndError/Error';
import {
  pageNotFound,
  portfolioNotFound,
  imageNotFound,
} from '../loadingAndError/errorMessages';
import { find, previousItem, nextItem } from '../utils/findItem';
import { routes } from './RouteManagement';
import { viewNames } from './galleryViewNames';

// ViewManagement
// determine the current view and render it
export class ViewManagement extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    galleryLoading: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      portfolios: PropTypes.array.isRequired,
    }),
    narrow: PropTypes.bool.isRequired,
    routeName: PropTypes.string.isRequired,
    router: PropTypes.shape({
      match: PropTypes.shape({
        params: PropTypes.object.isRequired,
      }).isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    fullscreen: PropTypes.object.isRequired,
    inFullscreen: PropTypes.bool.isRequired,
  };

  propsSetup() {
    const {
      galleryLoading,
      user,
      routeName,
      router,
      narrow,
      fullscreen,
      inFullscreen,
    } = this.props;

    const computedFromProps = {};

    if (galleryLoading) {
      computedFromProps.viewName = viewNames.GALLERY_LOADING;
      return computedFromProps;
    }
    if (!user) {
      computedFromProps.viewName = viewNames.NO_USER_ERROR;
      return computedFromProps;
    }

    if (routeName === routes.notFound) computedFromProps.error = pageNotFound;

    computedFromProps.info = [...user.info];

    if (!computedFromProps.error) {
      // always generate portfolioCoverImages so they can be preloaded in PreloadImageManagement
      computedFromProps.portfolioCoverImages = user.portfolios.map((port) => ({
        ...port.coverImage,
        urlTitle: port.urlTitle,
        title: port.title,
      }));

      if (routeName !== routes.root) {
        // by now route will always contain /:portfolio, so find portfolio
        const portfolioUrlTitle = router.match.params.portfolio;
        computedFromProps.portfolio = find(
          user.portfolios,
          (p) => p.urlTitle === portfolioUrlTitle,
        );
        if (!computedFromProps.portfolio) {
          computedFromProps.error = portfolioNotFound;
        } else if (routeName === routes.portfolio) {
          const multiplePortfolios = user.portfolios.length > 1;
          computedFromProps.prevPortfolio =
            multiplePortfolios &&
            previousItem(computedFromProps.portfolio, user.portfolios);
          computedFromProps.nextPortfolio =
            multiplePortfolios &&
            nextItem(computedFromProps.portfolio, user.portfolios);

          if (computedFromProps.portfolio.about)
            computedFromProps.info.unshift({
              id: `about-${computedFromProps.portfolio.id}`,
              title: `About ${computedFromProps.portfolio.title}`,
              isLink: false,
              text: computedFromProps.portfolio.about,
              urlHash: 'about',
            });
        } else if (routeName === routes.image) {
          const imageUrlTitle = router.match.params.image;
          const { images } = computedFromProps.portfolio;
          computedFromProps.image = find(
            images,
            (img) => img.urlTitle === imageUrlTitle,
          );
          if (!computedFromProps.image) {
            computedFromProps.error = imageNotFound(
              computedFromProps.portfolio,
            );
          } else {
            const multipleImages = images.length > 1;
            computedFromProps.prevImage =
              multipleImages && previousItem(computedFromProps.image, images);
            computedFromProps.nextImage =
              multipleImages && nextItem(computedFromProps.image, images);
          }
          computedFromProps.viewName =
            inFullscreen ||
            !fullscreen.fullscreenEnabled ||
            computedFromProps.error
              ? viewNames.IMAGE_PAGE
              : narrow
              ? viewNames.PORTFOLIO_NARROW_REQUEST_FULLSCREEN
              : viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN;
          return computedFromProps;
        }
      }
    }

    if (!router.location.hash) {
      if (routeName === routes.root) {
        computedFromProps.viewName = narrow
          ? viewNames.HOMEPAGE_NARROW_IMAGES
          : viewNames.HOMEPAGE_WIDE_IMAGES;
      } else {
        computedFromProps.viewName = narrow
          ? viewNames.PORTFOLIO_NARROW_IMAGES
          : viewNames.PORTFOLIO_WIDE_IMAGES;
      }
    } else if (router.location.hash === '#menu') {
      if (routeName === routes.root) {
        computedFromProps.viewName = narrow
          ? viewNames.HOMEPAGE_NARROW_MENU
          : viewNames.HOMEPAGE_WIDE_MENU;
      } else {
        computedFromProps.viewName = narrow
          ? viewNames.PORTFOLIO_NARROW_MENU
          : viewNames.PORTFOLIO_WIDE_MENU;
      }
    } else {
      computedFromProps.infoToShow = find(
        computedFromProps.info,
        (inf) => router.location.hash.slice(1) === inf.urlHash,
      );
      if (computedFromProps.infoToShow) {
        if (routeName === routes.root) {
          computedFromProps.viewName = narrow
            ? viewNames.HOMEPAGE_NARROW_INFO
            : viewNames.HOMEPAGE_WIDE_INFO;
        } else {
          computedFromProps.viewName = narrow
            ? viewNames.PORTFOLIO_NARROW_INFO
            : viewNames.PORTFOLIO_WIDE_INFO;
        }
      } else {
        if (routeName === routes.root) {
          computedFromProps.viewName = narrow
            ? viewNames.HOMEPAGE_NARROW_IMAGES
            : viewNames.HOMEPAGE_WIDE_IMAGES;
        } else {
          computedFromProps.viewName = narrow
            ? viewNames.PORTFOLIO_NARROW_IMAGES
            : viewNames.PORTFOLIO_WIDE_IMAGES;
        }
      }
    }

    return computedFromProps;
  }

  // shared nav functions
  handleClose = () => {
    const { location, history } = this.props.router;
    history.push(location.pathname);
  };

  handleRequestFullscreenClose = () => {
    const { match, location, history } = this.props.router;
    history.push(`/${match.params.portfolio}${location.hash}`);
  };

  render() {
    const {
      children,
      user,
      narrow,
      fullscreen,
      inFullscreen,
      router: { history, location },
    } = this.props;

    const {
      viewName,
      portfolioCoverImages,
      portfolio,
      prevPortfolio,
      nextPortfolio,
      image,
      prevImage,
      nextImage,
      info,
      infoToShow,
      error,
    } = this.propsSetup();

    let render;
    switch (viewName) {
      case viewNames.GALLERY_LOADING:
        return <Loading>Gallery Loading...</Loading>;
      case viewNames.NO_USER_ERROR:
        return <Error error="No user after finished loading." />;
      case viewNames.HOMEPAGE_WIDE_IMAGES:
      case viewNames.HOMEPAGE_WIDE_MENU:
      case viewNames.HOMEPAGE_WIDE_INFO: {
        render = ({ onComplete }) => (
          <HomePageWideContainer>
            <WideHeader
              breadcrumbsBase={user.displayName}
              breadcrumbsItem="Portfolios"
              currentPath={location.pathname}
              isInfoMenuShown={viewName === viewNames.HOMEPAGE_WIDE_MENU}
              info={info}
              handleClose={this.handleClose}
              disabled={viewName === viewNames.HOMEPAGE_WIDE_INFO}
            />
            <ImageGrid
              // use key prop so react creates a new component instance when imagesId changes
              key="allPortfolios"
              imagesId="allPortfolios"
              {...wideHomePageGridSpacing}
              images={portfolioCoverImages}
              showTitle
              onComplete={onComplete}
              error={error}
            />
            <InfoOverlay
              key="infoOverlay"
              isOpen={viewName === viewNames.HOMEPAGE_WIDE_INFO}
              infoToShow={infoToShow}
              handleClose={this.handleClose}
            />
          </HomePageWideContainer>
        );
        break;
      }
      case viewNames.HOMEPAGE_NARROW_IMAGES: {
        render = ({ onComplete }) => (
          <HomePageNarrowContainer>
            <NarrowHeader>{user.displayName}</NarrowHeader>
            <ImageGrid
              key="allPortfolios"
              imagesId="allPortfolios"
              images={portfolioCoverImages}
              showTitle
              {...narrowHomePageGridSpacing}
              onComplete={onComplete}
              error={error}
            />
            <HomePageNarrowFooter />
          </HomePageNarrowContainer>
        );
        break;
      }
      case viewNames.PORTFOLIO_WIDE_IMAGES:
      case viewNames.PORTFOLIO_WIDE_MENU:
      case viewNames.PORTFOLIO_WIDE_INFO:
      case viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN: {
        const disablePage =
          viewName === viewNames.PORTFOLIO_WIDE_INFO ||
          viewName === viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN;

        render = ({ onComplete }) => (
          <PortfolioPageWideContainer>
            <WideHeader
              breadcrumbsBase={user.displayName}
              breadcrumbsItem={
                portfolio ? portfolio.title : 'Portfolio Not Found'
              }
              currentPath={location.pathname}
              isInfoMenuShown={viewName === viewNames.PORTFOLIO_WIDE_MENU}
              info={info}
              handleClose={this.handleClose}
              disabled={disablePage}
            />
            <ImageGrid
              // use key prop so react creates a new component instance when imagesId changes
              key={portfolio && portfolio.id}
              imagesId={portfolio && portfolio.id}
              {...widePortfolioPageGridSpacing}
              images={portfolio && portfolio.images}
              toLinkBase={`/${portfolio ? portfolio.urlTitle : ''}`}
              onClick={fullscreen.enterFullscreen}
              onComplete={onComplete}
              error={error}
            />
            <PortfolioPageWideFooter
              portfolio={portfolio}
              prevPortfolio={prevPortfolio}
              nextPortfolio={nextPortfolio}
              inFullscreen={inFullscreen}
              fullscreen={fullscreen}
            />
            <WheelEventNavigation
              onSwipeRight={() => history.push(`/${prevPortfolio.urlTitle}`)}
              onSwipeLeft={() => history.push(`/${nextPortfolio.urlTitle}`)}
              disabled={disablePage}
            />
            <InfoOverlay
              key="infoOverlay"
              isOpen={viewName === viewNames.PORTFOLIO_WIDE_INFO}
              infoToShow={infoToShow}
              handleClose={this.handleClose}
            />
            <RequestFullscreenOverlay
              key="requestFullscreenOverlay"
              isOpen={viewName === viewNames.PORTFOLIO_WIDE_REQUEST_FULLSCREEN}
              enterFullscreen={fullscreen.enterFullscreen}
              handleRequestFullscreenClose={this.handleRequestFullscreenClose}
            />
          </PortfolioPageWideContainer>
        );
        break;
      }
      case viewNames.PORTFOLIO_NARROW_IMAGES: {
        render = ({ onComplete }) => (
          <PortfolioPageNarrowContainer>
            <NarrowHeader>
              {portfolio ? portfolio.title : user.displayName}
            </NarrowHeader>
            <ImageGrid
              // use key prop so react creates a new component instance when imagesId changes
              key={portfolio && portfolio.id}
              imagesId={portfolio && portfolio.id}
              {...narrowPortfolioPageGridSpacing}
              images={portfolio && portfolio.images}
              onClick={fullscreen.enterFullscreen}
              onComplete={onComplete}
              toLinkBase={portfolio ? portfolio.urlTitle : ''}
              error={error}
            />
            <PortfolioPageNarrowFooter
              portfolio={portfolio}
              prevPortfolio={prevPortfolio}
              nextPortfolio={nextPortfolio}
            />
            <WheelEventNavigation
              onSwipeRight={() => history.push(`/${prevPortfolio.urlTitle}`)}
              onSwipeLeft={() => history.push(`/${nextPortfolio.urlTitle}`)}
            />
          </PortfolioPageNarrowContainer>
        );
        break;
      }
      case viewNames.HOMEPAGE_NARROW_MENU:
      case viewNames.HOMEPAGE_NARROW_INFO:
      case viewNames.PORTFOLIO_NARROW_MENU:
      case viewNames.PORTFOLIO_NARROW_INFO: {
        render = () => (
          <MenuPageContainer>
            <NarrowHeader>{user.displayName}</NarrowHeader>
            <MenuPageItems info={info} infoToShow={infoToShow} />
            <MenuPageFooter
              handleClose={this.handleClose}
              title={`Back to ${
                portfolio ? `${portfolio.title} Portfolio` : 'All Portfolios'
              }`}
            />
          </MenuPageContainer>
        );
        break;
      }
      case viewNames.PORTFOLIO_NARROW_REQUEST_FULLSCREEN: {
        render = () => (
          <RequestFullscreenContainer>
            <RequestFullscreenButton
              enterFullscreen={fullscreen.enterFullscreen}
            />
            <MenuPageFooter
              handleClose={this.handleRequestFullscreenClose}
              title={`Back to ${portfolio.title} Portfolio`}
            />
          </RequestFullscreenContainer>
        );
        break;
      }
      case viewNames.IMAGE_PAGE: {
        render = ({ onComplete }) => (
          <ImagePage
            image={image}
            prevImage={prevImage}
            nextImage={nextImage}
            portfolio={portfolio}
            exitFullscreen={fullscreen.exitFullscreen}
            routerNavigateTo={history.push}
            onComplete={onComplete}
            error={error}
          />
        );
        break;
      }
      default:
        return <Error error="No view name case match in ViewManagement." />;
    }

    const view = {
      viewName,
      narrow,
      inFullscreen,
      user,
      portfolioCoverImages,
      portfolio,
      image,
      infoToShow,
      error,
    };

    return children({ view, render });
  }
}
