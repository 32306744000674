import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { RetryLink } from 'apollo-link-retry';
import * as Sentry from '@sentry/react';
import { App } from './App';
import { logError } from './shared/logError';
import { baseFontSize } from './gallery/galleryStyle';
import { colorScheme } from './colorScheme';
import './index.css';

// set font size on html element to use as base font size for rem units
document.documentElement.style.fontSize = `${baseFontSize}px`;
document.documentElement.style.color = colorScheme.normalText;
document.documentElement.style.fill = colorScheme.svgFill;
document.documentElement.style.backgroundColor = colorScheme.background;

const serverUrl = 'https://server.rafael.photo/graphql';

const httpLink = new HttpLink({ uri: serverUrl });

const retryLink = new RetryLink({ max: 30, delay: 200 });

const authLink = new ApolloLink((operation, forward) => {
  if (localStorage.getItem('token')) {
    // add token to the headers
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      logError(error);
      if (error.message === 'No current user!') {
        // TODO handle better than full page reload
        localStorage.clear();
        window.location.reload();
      }
    });
  if (networkError) logError(networkError);
});

const client = new ApolloClient({
  // note: have to concat with errorLink (afterware) on outside, can't add it to from array
  link: errorLink.concat(ApolloLink.from([retryLink, authLink, httpLink])),
  cache: new InMemoryCache(),
});

Sentry.init({
  dsn: 'https://f435978167314bd0bbc241bb89dc6ee2@o456943.ingest.sentry.io/5450508',
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
