import {
  HomeIcon,
  DashboardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  InfoCircledIcon,
  EnterFullScreenIcon,
  ExitFullScreenIcon,
  HamburgerMenuIcon,
} from '@radix-ui/react-icons';
import { IconButton } from './IconButton';
import { iconSize, iconButtonStyle } from '../galleryStyle';

export const HomeIconButton = (props) => (
  <IconButton {...props} to="/" title="All Portfolios" triggerKey="h">
    <HomeIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'translateY(2px)' }}
    />
  </IconButton>
);

export const GridIconButton = (props) => (
  <IconButton {...props} triggerKey="g">
    <DashboardIcon width={iconSize} height={iconSize} />
  </IconButton>
);

export const LeftArrowIconButton = (props) => (
  <IconButton {...props} triggerKey="ArrowLeft">
    <ArrowLeftIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.2)' }}
    />
  </IconButton>
);

export const RightArrowIconButton = (props) => (
  <IconButton {...props} triggerKey="ArrowRight">
    <ArrowRightIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.2)' }}
    />
  </IconButton>
);

export const MenuIconButton = (props) => (
  <IconButton {...props} title="Additional Information" triggerKey="m">
    <HamburgerMenuIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.1)' }}
    />
  </IconButton>
);

export const EnterFullscreenIconButton = (props) => (
  <IconButton {...props} title="Enter Fullscreen" triggerKey="f">
    <EnterFullScreenIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.1)' }}
    />
  </IconButton>
);

export const ExitFullscreenIconButton = (props) => (
  <IconButton {...props} title="Exit Fullscreen" triggerKey="f">
    <ExitFullScreenIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.15)' }}
    />
  </IconButton>
);

export const InfoIconButton = (props) => (
  <IconButton
    {...props}
    triggerKey="i"
    useExtendedTouchActive
    focusFromKeyStyle={iconButtonStyle.hoverStyle}
  >
    <InfoCircledIcon
      width={iconSize}
      height={iconSize}
      style={{ transform: 'scale(1.1)' }}
    />
  </IconButton>
);
