import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Simplemark } from '../../shared/Simplemark';
import { RequestFullscreenButton } from '../ui/RequestFullscreenButton';
import { InteractiveLink } from '../ui/InteractiveLink';
import { passiveNoCaptureEventOptions } from '../utils/eventListenerOptions';
import {
  pageSideSpace,
  stickyFooterHeight,
  wideHeaderHeightSingleLine,
  textOverlayMaxWidth,
  requestFullscreenOverlayMaxWidth,
  infoOverlayTitleFontSizeRem,
} from '../galleryStyle';
import { overlayColorScheme } from '../../colorScheme';
import './Overlay.css';

Modal.setAppElement('#root');

const overlayTopSpaceBelowSingleLineHeader = 4;
const overlayBottomSpaceAboveFooter = 4;
const backgroundStyle = {
  backgroundColor: overlayColorScheme.overlayPageBackground,
  paddingTop: `${
    wideHeaderHeightSingleLine + overlayTopSpaceBelowSingleLineHeader
  }px`,
  paddingBottom: `${stickyFooterHeight + overlayBottomSpaceAboveFooter}px`,
  paddingLeft: `${pageSideSpace}px`,
  paddingRight: `${pageSideSpace}px`,
};

class Overlay extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    contentLabel: PropTypes.string.isRequired,
    maxWidth: PropTypes.number.isRequired,
    className: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener(
      'keydown',
      this.handleKeyDown,
      passiveNoCaptureEventOptions,
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'keydown',
      this.handleKeyDown,
      passiveNoCaptureEventOptions,
    );
  }

  handleKeyDown = (e) => {
    if (this.props.isOpen && e.key === 'Escape') {
      this.props.onRequestClose();
    }
  };

  render() {
    const {
      isOpen,
      title,
      children,
      contentLabel,
      maxWidth,
      className,
      onRequestClose,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        bodyOpenClassName="Overlay-open-body-class"
        portalClassName="Overlay-portal"
        overlayClassName="Overlay-background"
        style={{
          overlay: backgroundStyle,
          content: {
            maxWidth: `${maxWidth}px`,
            color: overlayColorScheme.overlayText,
            backgroundColor: overlayColorScheme.overlayContentBackground,
            borderColor: overlayColorScheme.overlayBorder,
          },
        }}
        className={className}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
      >
        {title && (
          <h1
            className="Overlay-header"
            style={{ fontSize: `${infoOverlayTitleFontSizeRem}rem` }}
          >
            {title}
          </h1>
        )}
        {children}
        <footer className="Overlay-footer">
          <InteractiveLink onClick={onRequestClose}>Close</InteractiveLink>
        </footer>
      </Modal>
    );
  }
}

export const InfoOverlay = ({ isOpen, infoToShow = {}, handleClose }) => {
  const overlayBodyRef = React.useCallback((el) => {
    el && el.focus();
  }, []);

  // to center text that's less than 75 chars (estimate for 1 line)
  const textClassName =
    infoToShow.text && infoToShow.text.length < 75 ? ' Overlay-short-text' : '';

  return (
    <Overlay
      isOpen={isOpen}
      className="Overlay-content-text"
      maxWidth={textOverlayMaxWidth}
      contentLabel={`${infoToShow.title} Information`}
      onRequestClose={handleClose}
      title={infoToShow.title}
    >
      <Simplemark
        as="main"
        className={`Overlay-body-text${textClassName}`}
        ref={overlayBodyRef}
        tabIndex="0"
      >
        {infoToShow.text}
      </Simplemark>
    </Overlay>
  );
};

InfoOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  infoToShow: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
};

export const RequestFullscreenOverlay = ({
  isOpen,
  enterFullscreen,
  handleRequestFullscreenClose,
}) => {
  return (
    <Overlay
      isOpen={isOpen}
      className="Overlay-content-request-fullscreen"
      maxWidth={requestFullscreenOverlayMaxWidth}
      contentLabel="Request Enter Fullscreen"
      onRequestClose={handleRequestFullscreenClose}
    >
      <div className="Overlay-request-fullscreen-button-container">
        <RequestFullscreenButton enterFullscreen={enterFullscreen} />
      </div>
    </Overlay>
  );
};

RequestFullscreenOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  enterFullscreen: PropTypes.func.isRequired,
  handleRequestFullscreenClose: PropTypes.func.isRequired,
};
