import PropTypes from 'prop-types';
import {
  imagePageInfoOverlayTitleFontSizeRem,
  imagePageInfoOverlayContentFontSizeRem,
} from '../galleryStyle';
import { colorScheme } from '../../colorScheme';
import './ImagePageOverlay.css';

export function ImagePageOverlay({ image, isShown }) {
  return (
    <div
      className="ImagePageOverlay"
      style={{
        display: isShown ? 'block' : 'none',
        backgroundColor: colorScheme.imagePageOverlayBackground,
      }}
    >
      <h1
        className="ImagePageOverlay-title"
        style={{ fontSize: `${imagePageInfoOverlayTitleFontSizeRem}rem` }}
      >
        {image.title}
      </h1>
      <div
        className="ImagePageOverlay-content"
        style={{ fontSize: `${imagePageInfoOverlayContentFontSizeRem}rem` }}
      >
        {image.location
          ? `${image.location}${image.otherInfo ? ', ' : ''}`
          : ''}
        {image.otherInfo ? image.otherInfo : ''}
      </div>
    </div>
  );
}

ImagePageOverlay.propTypes = {
  isShown: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
    location: PropTypes.string,
    otherInfo: PropTypes.string,
  }).isRequired,
};
