// Array.prototype.find is not transpiled, so can't use unless add a polyfill
export function find(array, callback) {
  for (let i = 0; i < array.length; i++) {
    if (callback(array[i])) return array[i];
  }
  return undefined;
}

export function wrapIndex(i, length) {
  if (i < 0) return length - 1;
  if (i > length - 1) return 0;
  return i;
}

function itemByOffset(item, list, offset) {
  const i = list.indexOf(item);
  return list[wrapIndex(i + offset, list.length)];
}

export function previousItem(item, list) {
  return itemByOffset(item, list, -1);
}

export function nextItem(item, list) {
  return itemByOffset(item, list, 1);
}
