import { Component } from 'react';
import PropTypes from 'prop-types';
import fscreen from 'fscreen';
import { passiveNoCaptureEventOptions } from '../utils/eventListenerOptions';

export class FullscreenManagement extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    router: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    // To fix Safari 10.1.2 bug (update: bug still present in Safari 14.1)
    // when refresh page in fullscreen mode, window stays in
    // fullscreen mode but Safari doesn’t recognize it as such and
    // none of the exit/enter fullscreen calls work (have to physically press
    // the escape key to exit fullscreen mode), so have to check to see
    // if window is equal to screen height and width to tell if inFullscreen,
    // also need to set resize event listener to detect initial
    // exit of fullscreen mode b/c fullscreenchange event doesn't fire,
    // after the initial exit of fullscreen mode everything works normally
    const fullscreenElement = fscreen.fullscreenElement !== null;
    const fullscreenHeight = window.screen.height === window.innerHeight;
    const fullscreenWidth = window.screen.width === window.innerWidth;
    this.state = {
      inFullscreen: this.fullscreen.fullscreenEnabled
        ? fullscreenElement || (fullscreenHeight && fullscreenWidth)
        : false,
    };
  }

  componentDidMount() {
    if (this.state.inFullscreen) {
      // to detect initial exit of fullscreen mode to fix bug in Safari
      window.addEventListener(
        'resize',
        this.handleInitialResize,
        passiveNoCaptureEventOptions,
      );
    }
    fscreen.addEventListener(
      'fullscreenchange',
      this.handleFsChange,
      passiveNoCaptureEventOptions,
    );
  }

  componentWillUnmount() {
    fscreen.removeEventListener(
      'fullscreenchange',
      this.handleFsChange,
      passiveNoCaptureEventOptions,
    );
  }

  setFullscreenState = (inFullscreen) => {
    if (this.state.inFullscreen === inFullscreen) return;
    if (!inFullscreen) this.exitFullscreenPathChange();
    this.setState({ inFullscreen });
  };

  exitFullscreenPathChange = () => {
    const path = this.props.router.location.pathname.split('/').slice(1);
    if (path.length === 2) {
      this.props.router.history.push(`/${path[0]}`);
    }
  };

  handleFsChange = () => {
    const inFullscreen = fscreen.fullscreenElement !== null;
    this.setFullscreenState(inFullscreen);
  };

  handleInitialResize = () => {
    // only called if inFullscreen when component mounted
    // used to fix bug in Safari, see note in constructor
    this.exitFullscreenPathChange();
    this.setState({ inFullscreen: false });
    window.removeEventListener(
      'resize',
      this.handleInitialResize,
      passiveNoCaptureEventOptions,
    );
  };

  fullscreen = {
    fullscreenEnabled: fscreen.fullscreenEnabled,
    enterFullscreen: () => {
      if (
        this.fullscreen.fullscreenEnabled &&
        fscreen.fullscreenElement === null
      ) {
        this.setFullscreenState(true);
        fscreen.requestFullscreen(document.documentElement);
      }
    },
    exitFullscreen: () => {
      if (
        this.fullscreen.fullscreenEnabled &&
        fscreen.fullscreenElement !== null
      ) {
        this.setFullscreenState(false);
        fscreen.exitFullscreen();
      }
    },
  };

  render() {
    return this.props.children({
      fullscreen: this.fullscreen,
      inFullscreen: this.state.inFullscreen,
    });
  }
}
