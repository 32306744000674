import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  preloadImagesInSequence,
  preloadedImageArrayIds,
  cancelPreloads,
} from '../image/preloadImages';
import { wrapIndex } from '../utils/findItem';
import { imagePageSizeConstraints } from '../imagePage/imagePageSizeConstraints';
import { viewNames } from './galleryViewNames';
import {
  wideHomePageGridSpacing,
  widePortfolioPageGridSpacing,
  narrowHomePageGridSpacing,
  narrowPortfolioPageGridSpacing,
} from '../galleryStyle';

export class PreloadImageManagement extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    viewName: PropTypes.oneOf(
      Object.keys(viewNames).map((key) => viewNames[key]),
    ).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      portfolios: PropTypes.array.isRequired,
    }),
    portfolio: PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.array.isRequired,
    }),
    image: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    portfolioCoverImages: PropTypes.array,
    narrow: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      (this.props.portfolio && this.props.portfolio.id) !==
        (prevProps.portfolio && prevProps.portfolio.id) ||
      (this.props.image && this.props.image.id) !==
        (prevProps.image && prevProps.image.id) ||
      this.props.narrow !== prevProps.narrow
    ) {
      cancelPreloads();
    }
  }

  componentWillUnmount() {
    cancelPreloads();
  }

  preloadImages = () => {
    // setTimeout 0 to prevent race condition with componentDidUpdate
    // where preloadImages() is called in a child's componentDidUpdate
    // as the onComplete  callback and would run before this component's componentDidUpdate
    window.setTimeout(() => {
      if (this.props.viewName === viewNames.IMAGE_PAGE) {
        this.preloadImagePagePortfolio(() => {
          this.preloadPortfolioCoverImages();
          this.preloadPortfolioGrids();
        });
      } else {
        this.preloadPortfolioCoverImages(() => {
          this.preloadPortfolioGrids();
          this.preloadImagePagePortfolio();
        });
      }
    }, 0);
  };

  preloadPortfolioCoverImages(onComplete) {
    const { user, portfolioCoverImages, narrow } = this.props;
    if (!user || !portfolioCoverImages) {
      onComplete && onComplete();
      return;
    }

    const portfolioCoverImageSize = narrow
      ? narrowHomePageGridSpacing.maxImageSize
      : wideHomePageGridSpacing.maxImageSize;

    const preloadPortfolioCoverImagesId = `${user.id}-portfoliosCoverImages-maxImageSize${portfolioCoverImageSize}`;

    if (!preloadedImageArrayIds[preloadPortfolioCoverImagesId]) {
      preloadImagesInSequence({
        imageArray: portfolioCoverImages,
        sizeConstraints: { longSide: portfolioCoverImageSize },
        onComplete: () => {
          preloadedImageArrayIds[preloadPortfolioCoverImagesId] = true;
          onComplete && onComplete();
        },
      });
    } else onComplete && onComplete();
  }

  // preload portfolio page image grids for all portfolios for a given user
  preloadPortfolioGrids(onComplete) {
    const { user, portfolio, narrow } = this.props;
    if (!user) {
      onComplete && onComplete();
      return;
    }

    const maxImageSize = narrow
      ? narrowPortfolioPageGridSpacing.maxImageSize
      : widePortfolioPageGridSpacing.maxImageSize;

    const preloadPortfolioGridImagesId = `${user.id}-portfoliosGrid-maxImageSize${maxImageSize}`;

    if (!preloadedImageArrayIds[preloadPortfolioGridImagesId]) {
      const portfolioGridImages = user.portfolios.map((p) => p.images);
      const index = user.portfolios.indexOf(portfolio);
      const startIndex = wrapIndex(index + 1, portfolioGridImages.length);
      const endIndex = wrapIndex(index - 1, portfolioGridImages.length);
      if (portfolioGridImages.length > 1) {
        preloadImagesInSequence({
          imageArray: portfolioGridImages,
          sizeConstraints: { longSide: maxImageSize },
          startIndex,
          endIndex,
          onComplete: () => {
            preloadedImageArrayIds[preloadPortfolioGridImagesId] = true;
            onComplete && onComplete();
          },
        });
      }
    } else onComplete && onComplete();
  }

  preloadImagePagePortfolio(onComplete) {
    const { image, portfolio } = this.props;
    if (!portfolio || portfolio.images.length === 0) {
      onComplete && onComplete();
      return;
    }

    const preloadImagePageImagesId =
      portfolio && `${portfolio.id}-${imagePageSizeConstraints.sizeId}`;

    if (!preloadedImageArrayIds[preloadImagePageImagesId]) {
      const { images } = portfolio;
      const numberOfImages = images.length;

      let startIndex = 0;
      let endIndex = numberOfImages - 1;
      if (image) {
        const index = images.indexOf(image);
        startIndex = wrapIndex(index + 1, numberOfImages);
        endIndex = wrapIndex(index - 1, numberOfImages);
      }

      preloadImagesInSequence({
        imageArray: images,
        sizeConstraints: imagePageSizeConstraints,
        startIndex,
        endIndex,
        onComplete: () => {
          preloadedImageArrayIds[preloadImagePageImagesId] = true;
          onComplete && onComplete();
        },
      });
    } else onComplete && onComplete();
  }

  render() {
    return this.props.children({ onComplete: this.preloadImages });
  }
}
