import PropTypes from 'prop-types';
import { ImageLoadingAndError } from './ImageLoadingAndError';
import './ImageFit100PercentWidthAsLongDimension.css';

// long dimension of image is 100% of the width of the container
// so horizontal and vertical images are the same size
export function ImageFit100PercentWidthAsLongDimension({
  imageTitle,
  src,
  srcSet,
  imageWidth,
  imageHeight,
  imageLoaded,
  imageError,
  LinkComponent,
  linkComponentProps,
}) {
  const ImageContainer = LinkComponent || 'div';

  const boxOfPaddingStyle =
    imageWidth > imageHeight
      ? {
          // use calc and don't do math in JS b/c of rounding bug in MS Edge
          paddingTop: `calc(100% * ${imageHeight} / ${imageWidth})`,
          paddingLeft: '100%',
        }
      : {
          paddingTop: '100%',
          paddingLeft: `calc(100% * ${imageWidth} / ${imageHeight})`,
        };

  return (
    <div className="ImageFitWidth-box-of-padding" style={boxOfPaddingStyle}>
      <ImageContainer
        {...linkComponentProps}
        className={`ImageFitWidth-box-of-padding-child${
          LinkComponent ? ' ImageFitWidth-link' : ''
        }`}
      >
        {imageLoaded ? (
          <img
            alt={imageTitle}
            src={src}
            srcSet={srcSet}
            className="ImageFitWidth-image"
          />
        ) : (
          <ImageLoadingAndError
            imageTitle={imageTitle}
            imageError={imageError}
            withBorder
          />
        )}
      </ImageContainer>
    </div>
  );
}

ImageFit100PercentWidthAsLongDimension.propTypes = {
  imageTitle: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageLoaded: PropTypes.bool.isRequired,
  imageError: PropTypes.bool.isRequired,
  LinkComponent: PropTypes.any,
  linkComponentProps: PropTypes.object,
};
