import * as React from 'react';
import PropTypes from 'prop-types';
import { ImagePageOverlay } from './ImagePageOverlay';
import { TouchSwipe } from './TouchSwipe';
import { imagePageSizeConstraints } from './imagePageSizeConstraints';
import { ImageManagement } from '../image/ImageManagement';
import { ImageFit100PercentBox } from '../image/ImageFit100PercentBox';
import { FooterSticky } from '../footer/Footer';
import {
  GridIconButton,
  LeftArrowIconButton,
  RightArrowIconButton,
  InfoIconButton,
} from '../ui/IconButtons';
import { WheelEventNavigation } from '../utils/WheelEventNavigation';
import { PageNotFound } from '../loadingAndError/PageNotFound';
import { noPassiveCaptureEventOptions } from '../utils/eventListenerOptions';
import {
  stickyFooterHeight,
  stickyFooterCenterButtonMargin,
  stickyFooterGridButtonMargin,
  stickyFooterInfoButtonMargin,
  isIPad,
} from '../galleryStyle';
import './ImagePage.css';

export const ImagePage = ({
  portfolio,
  image,
  prevImage,
  nextImage,
  onComplete,
  error,
  exitFullscreen,
  routerNavigateTo,
}) => {
  const [showInfo, setShowInfo] = React.useState(false);

  const handleInfoButtonStateChange = React.useCallback(({ state }) => {
    if (state.hover || state.active || state.focus === 'focusFromKey') {
      setShowInfo(() => true);
    } else {
      setShowInfo(() => false);
    }
  }, []);

  // preventDefault() on wheel event so no elastic vertical scroll on ImagePage
  React.useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    document.addEventListener(
      'wheel',
      handleWheel,
      noPassiveCaptureEventOptions,
    );

    return () => {
      document.removeEventListener(
        'wheel',
        handleWheel,
        noPassiveCaptureEventOptions,
      );
    };
  });

  // sometimes the browser tries to select text when the user misses
  // the info button on touch devices, so deselect any selection
  const deselectText = React.useCallback(() => {
    document.getSelection().removeAllRanges();
  }, []);
  React.useEffect(() => {
    document.addEventListener('selectionchange', deselectText);
    return () => {
      document.removeEventListener('selectionchange', deselectText);
    };
  });

  const handleContextMenuEventOnInfoButton = React.useCallback((e) => {
    // windows (all browsers) will show the context menu on long press (on any part of the screen)
    // so call preventDefault to not show the context menu when pressing the info button
    if (/Windows/.test(window.navigator.userAgent)) {
      e.preventDefault();
    }
  }, []);

  const portfolioPath = `/${portfolio.urlTitle}`;
  const prevImagePath =
    prevImage && `/${portfolio.urlTitle}/${prevImage.urlTitle}`;
  const nextImagePath =
    nextImage && `/${portfolio.urlTitle}/${nextImage.urlTitle}`;

  return (
    <>
      <TouchSwipe
        onSwipeLeft={() => nextImage && routerNavigateTo(nextImagePath)}
        onSwipeRight={() => prevImage && routerNavigateTo(prevImagePath)}
        onDoubleClick={() => routerNavigateTo(portfolioPath)}
        className="ImagePage-swipe"
        style={{ height: `calc(100% - ${stickyFooterHeight}px)` }}
        key={image && image.id}
      >
        {image ? (
          [
            <ImagePageOverlay
              isShown={showInfo}
              image={image}
              key={`overlay-for-${image.id}`}
            />,
            <ImageManagement
              key={image.id}
              image={image}
              sizeConstraints={imagePageSizeConstraints}
              onComplete={onComplete}
            >
              {({ src, srcSet, imageLoaded, imageError }) => (
                <ImageFit100PercentBox
                  imageTitle={image.title}
                  src={src}
                  srcSet={srcSet}
                  imageLoaded={imageLoaded}
                  imageError={imageError}
                />
              )}
            </ImageManagement>,
          ]
        ) : (
          <PageNotFound {...error} />
        )}
      </TouchSwipe>
      <FooterSticky>
        <GridIconButton
          to={portfolioPath}
          title={`${portfolio.title} Portfolio`}
          style={{ marginLeft: `${stickyFooterGridButtonMargin}px` }}
          onClick={isIPad ? exitFullscreen : undefined}
        />
        <LeftArrowIconButton
          to={prevImagePath}
          disabled={!prevImage}
          title={prevImage && `Previous Image: ${prevImage.title}`}
          style={{ marginLeft: `-${stickyFooterCenterButtonMargin}px` }}
        />
        <RightArrowIconButton
          to={nextImagePath}
          disabled={!nextImage}
          title={nextImage && `Next Image: ${nextImage.title}`}
          style={{ marginLeft: `${stickyFooterCenterButtonMargin}px` }}
        />
        <InfoIconButton
          disabled={!image}
          onStateChange={handleInfoButtonStateChange}
          style={{ marginLeft: `${stickyFooterInfoButtonMargin}px` }}
          onContextMenu={handleContextMenuEventOnInfoButton}
        />
      </FooterSticky>
      <WheelEventNavigation
        onSwipeLeft={() => nextImage && routerNavigateTo(nextImagePath)}
        onSwipeRight={() => prevImage && routerNavigateTo(prevImagePath)}
      />
    </>
  );
};

ImagePage.propTypes = {
  portfolio: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  prevImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  nextImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    urlTitle: PropTypes.string.isRequired,
  }),
  routerNavigateTo: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  error: PropTypes.object,
};
