import * as detectIt from 'detect-it';
import { colorScheme } from '../colorScheme';

const primaryInputTouch = detectIt.primaryInput === 'touch';

// used for wide/narrow media query
export const maxSmallDimensionPx = 550;

// icon button size
export const iconSize = primaryInputTouch ? 30 : 26;

// on iPad (iPad OS v14.5) increase footer bottom padding to accommodate swipe up bar (app switcher ui),
// as well as only enter full screen on image page, not on portfolio page,
// because iPad OS header covers site header (exit full screen when leave image page),
// as well as can’t scroll up because iPad OS exits fullscreen instead of scrolling on swipe down gesture
export const isIPad =
  /iPad/.test(window.navigator.userAgent) ||
  (/Macintosh/.test(window.navigator.userAgent) &&
    primaryInputTouch &&
    window.screen.width > maxSmallDimensionPx);

/////////////////////////////////////////////////////////////////////////////
// font sizes

// set on html element and used as base for rem sizing
export const baseFontSize = primaryInputTouch ? 22 : 18;

export const loadingFontSizeRem = 1.5;

// wide page
export const wideHeaderFontSizeRem = 1.15;
export const infoOverlayTitleFontSizeRem = 1.25;

// narrow page
export const narrowHeaderFontSizeRem = 1.4;
export const menuPageItemTitleFontSizeRem = 1.15;

// portfolio titles on home page
export const imageGridTitleFontSizeRem = 1.05;

// image page info overlay
export const imagePageInfoOverlayTitleFontSizeRem = primaryInputTouch
  ? 1.6
  : 1.7;
export const imagePageInfoOverlayContentFontSizeRem = 1.1;

// request fullscreen button
export const requestFullscreenButtonFontSizeRem = 1.5;

/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// wide page spacing

// global max content width and side space
// note that maxContentWidth doesn't apply to image grids
export const maxContentWidth = 940;
export const pageSideSpace = primaryInputTouch ? 10 : 30;

// overlay max width
export const textOverlayMaxWidth = 680;
export const requestFullscreenOverlayMaxWidth = 600;

// wide header spacing, required for the Overlay
// to know how much space to allow for the header
export const wideHeaderLineHeight = Math.round(
  wideHeaderFontSizeRem * baseFontSize * 1.15,
);
export const wideHeaderPaddingTop = primaryInputTouch ? 7 : 6;
export const wideHeaderPaddingBottom = primaryInputTouch ? 7 : 6;
export const wideHeaderHeightSingleLine =
  wideHeaderLineHeight + wideHeaderPaddingTop + wideHeaderPaddingBottom;

/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// sticky footer spacing

export const stickyFooterPaddingTop = 10;
// increase bottom padding on iPad to accommodate swipe up bar (app switcher ui)
export const stickyFooterPaddingBottom = isIPad ? 22 : 10;
export const stickyFooterHeight =
  iconSize + stickyFooterPaddingBottom + stickyFooterPaddingTop;
export const stickyFooterCenterButtonMargin = primaryInputTouch ? 26 : 22;
export const stickyFooterHomeButtonMargin = primaryInputTouch ? -105 : -95;
export const stickyFooterGridButtonMargin = stickyFooterHomeButtonMargin;
export const stickyFooterFullscreenButtonMargin = primaryInputTouch ? 103 : 93;
export const stickyFooterInfoButtonMargin =
  stickyFooterFullscreenButtonMargin - 1;

/////////////////////////////////////////////////////////////////////////////
// narrow page spacing

// space at the bottom of the page for narrow scroll views,
// useful on touch devices when scroll to bottom
// so there is ample padding for easy access to footer buttons
export const narrowScrollPageBottomSpace = 120;

// narrow multi button footer spacing
export const footerNarrowMultiButtonColumnGap = primaryInputTouch ? 40 : 30;
export const footerNarrowMultiButtonRowGap = primaryInputTouch ? 35 : 25;

/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// image grid spacing

export const widePortfolioPageGridSpacing = {
  columns: 2,
  maxImageSize: 396,
  topSpace: 50,
  bottomSpace: 110,
  sideSpace: primaryInputTouch ? 0 : pageSideSpace,
  rowSpace: 85,
  maxColumnSpace: 68,
  minColumnSpace: 34,
  maxColumnSpaceNoConsecutiveHorizontalImages: 20,
  minColumnSpaceNoConsecutiveHorizontalImages: 0,
};

export const wideHomePageGridSpacing = {
  ...widePortfolioPageGridSpacing,
};

export const narrowPortfolioPageGridSpacing = {
  columns: 1,
  topSpace: 42,
  bottomSpace: 100,
  maxImageSize: primaryInputTouch
    ? Math.min(window.screen.width, window.screen.height)
    : 396,
  rowSpace: 100,
  sideSpace: 0,
};

export const narrowHomePageGridSpacing = {
  ...narrowPortfolioPageGridSpacing,
  rowSpace: 70,
};

/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// <Interactive> components color scheme

export const interactiveLinkStyle = {
  hoverStyle: { color: colorScheme.interactedColor },
  activeStyle: { color: colorScheme.interactedColor },
  focusFromKeyStyle: {
    outlineOffset: '0px',
    outline: `2px dotted ${colorScheme.focusOutlineColor}`,
    textDecoration: 'none',
  },
  // disabled links render as regular text, so no disabledStyle
  disabledStyle: {},
};

export const iconButtonStyle = {
  hoverStyle: { fill: colorScheme.interactedColor },
  activeStyle: { fill: colorScheme.interactedColor },
  focusFromKeyStyle: {
    outline: `2px dotted ${colorScheme.focusOutlineColor}`,
    outlineOffset: '2px',
  },
  disabledStyle: { opacity: '0.5' },
};

export const imageFocusFromKeyStyle = {
  outline: `3px dotted ${colorScheme.focusOutlineColor}`,
  outlineOffset: '0px',
};

/////////////////////////////////////////////////////////////////////////////
