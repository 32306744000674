import PropTypes from 'prop-types';
import './ImageLoadingAndError.css';

export function ImageLoadingAndError({
  imageTitle,
  imageError,
  withBorder,
  className,
  style,
  ...props
}) {
  const newClassName = className
    ? `${className} ImageLoadingAndError`
    : 'ImageLoadingAndError';

  const newStyle = withBorder ? { ...style, border: '1px solid' } : style;

  return (
    <div {...props} className={newClassName} style={newStyle}>
      {imageError
        ? `Error Loading ${imageTitle} Image`
        : `Loading ${imageTitle} Image...`}
    </div>
  );
}

ImageLoadingAndError.propTypes = {
  imageTitle: PropTypes.string.isRequired,
  imageError: PropTypes.bool.isRequired,
  withBorder: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
