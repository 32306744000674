import { logError } from '../../shared/logError';

export const loadedImages = {};

export function loadImage({ src, srcset, onComplete }) {
  let canceled = false;
  const image = new Image();
  const load = (e) => {
    loadedImages[src] = true;
    loadedImages[srcset] = true;
    !canceled && onComplete && onComplete(e);
    image.removeEventListener('load', load);
    image.removeEventListener('error', error);
  };
  const error = (e) => {
    image.removeEventListener('load', load);
    image.removeEventListener('error', error);
    if (!canceled) {
      logError({
        error: e,
        src,
        srcset,
        alreadyLoadedSrc: loadedImages[src],
        alreadyLoadedSrcset: loadedImages[srcset],
      });
      onComplete && onComplete(e);
    }
  };
  image.addEventListener('load', load);
  image.addEventListener('error', error);
  if (srcset) image.srcset = srcset;
  if (src) image.src = src;

  return function cancel() {
    // so onComplete() callback isn't called on canceled images
    // b/c the callback may refer to an unmounted component, e.g. call cancel in componentWillUnmount
    canceled = true;
  };
}
