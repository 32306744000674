import PropTypes from 'prop-types';
import { InteractiveLink } from '../ui/InteractiveLink';
import { InfoMenu } from './InfoMenu';
import {
  maxContentWidth,
  pageSideSpace,
  narrowHeaderFontSizeRem,
  wideHeaderFontSizeRem,
  wideHeaderLineHeight,
  wideHeaderPaddingTop,
  wideHeaderPaddingBottom,
} from '../galleryStyle';
import { colorScheme } from '../../colorScheme';

import './Header.css';

export const NarrowHeader = ({ children }) => (
  <header
    className="NarrowHeader"
    style={{ fontSize: `${narrowHeaderFontSizeRem}rem` }}
  >
    {children}
  </header>
);

const wideHeaderSpacing = {
  fontSize: `${wideHeaderFontSizeRem}rem`,
  maxWidth: `${maxContentWidth + 2 * pageSideSpace}px`,
  lineHeight: `${wideHeaderLineHeight}px`,
  paddingTop: `${wideHeaderPaddingTop}px`,
  paddingBottom: `${wideHeaderPaddingBottom}px`,
  paddingLeft: `${pageSideSpace}px`,
  paddingRight: `${pageSideSpace}px`,
};

export const WideHeader = ({
  breadcrumbsBase,
  breadcrumbsItem,
  currentPath,
  info,
  isInfoMenuShown,
  handleClose,
  disabled,
}) => {
  return (
    <header
      className="WideHeader-container"
      style={{ backgroundColor: colorScheme.background }}
    >
      <div className="WideHeader-sub-container" style={wideHeaderSpacing}>
        <nav className="WideHeader-breadcrumbs">
          <InteractiveLink to={'/'} disabled={disabled || currentPath === '/'}>
            {breadcrumbsBase}
          </InteractiveLink>
          <span className="WideHeader-breadcrumbs-separator">/</span>
          <span>{breadcrumbsItem}</span>
        </nav>
        <InfoMenu
          isShown={isInfoMenuShown}
          info={info}
          currentPath={currentPath}
          handleClose={handleClose}
          disabled={disabled}
        />
      </div>
    </header>
  );
};

WideHeader.propTypes = {
  breadcrumbsBase: PropTypes.string.isRequired,
  breadcrumbsItem: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  isInfoMenuShown: PropTypes.bool.isRequired,
  info: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
