export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function getScrollPosition() {
  // alias for window.scrollY, mdn suggest pageYOffset for cross browser compatibility
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY#Notes
  return window.pageYOffset;
}

export function setScrollPosition(scrollPos) {
  window.scrollTo(0, scrollPos);
}

export function lockScroll() {
  const scrollPos = getScrollPosition();
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPos}px`;
  return scrollPos;
}

export function unlockScroll(scrollPos) {
  document.body.style.position = null;
  document.body.style.top = null;
  setScrollPosition(scrollPos);
}
