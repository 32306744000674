import { Route, Switch } from 'react-router-dom';
import { Gallery } from './gallery/Gallery';
import { CodeSplit } from './user/utils/CodeSplit';
import { UserLoading } from './user/utils/UserLoading';

export function App() {
  return (
    <Switch>
      <Route
        path="/user"
        render={(routeProps) => (
          <CodeSplit
            module={import('./user/User')}
            componentWhileLoading={() => (
              <UserLoading>Loading chunk...</UserLoading>
            )}
            componentOnError={({ error }) => (
              <UserLoading>Error: {error}</UserLoading>
            )}
            componentProps={routeProps}
          />
        )}
      />
      <Route component={Gallery} />
    </Switch>
  );
}
