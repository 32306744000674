import * as React from 'react';
import PropTypes from 'prop-types';
import { noPassiveNoCaptureEventOptions } from './eventListenerOptions';

const wheelDeltaSwipe = 30;
const wheelDeltaReset = 15;

// track used wheel event as a singleton property so it doesn't get reset
// when the component unmounts and remounts when switching between views
let usedWheelEventMagnitude = false;
let usedWheelEventTime = false;

export const WheelEventNavigation = ({
  onSwipeLeft,
  onSwipeRight,
  disabled = false,
}) => {
  const handleWheelEvent = React.useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
        if (!disabled) {
          e.preventDefault();
        }

        if (usedWheelEventMagnitude || usedWheelEventTime || disabled) {
          if (Math.abs(e.deltaX) <= wheelDeltaReset) {
            usedWheelEventMagnitude = false;
          }
          return;
        }

        if (Math.abs(e.deltaX) > wheelDeltaSwipe) {
          usedWheelEventMagnitude = true;
          usedWheelEventTime = true;
          window.setTimeout(() => {
            usedWheelEventTime = false;
          }, 500);

          if (e.deltaX > wheelDeltaSwipe) {
            onSwipeLeft && onSwipeLeft();
          } else if (e.deltaX < -wheelDeltaSwipe) {
            onSwipeRight && onSwipeRight();
          }
        }
      }
    },
    [onSwipeLeft, onSwipeRight, disabled],
  );

  React.useEffect(() => {
    document.addEventListener(
      'wheel',
      handleWheelEvent,
      noPassiveNoCaptureEventOptions,
    );
    return () => {
      document.removeEventListener(
        'wheel',
        handleWheelEvent,
        noPassiveNoCaptureEventOptions,
      );
    };
  }, [handleWheelEvent]);

  return null;
};

WheelEventNavigation.propTypes = {
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  disabled: PropTypes.bool,
};
