import PropTypes from 'prop-types';
import './UserLoading.css';

export function UserLoading({ children }) {
  return (
    <div className="UserLoading-container">
      <div className="UserLoading-text">{children}</div>
    </div>
  );
}

UserLoading.propTypes = {
  children: PropTypes.node.isRequired,
};
