import { Component } from 'react';
import PropTypes from 'prop-types';

export class TouchSwipe extends Component {
  static propTypes = {
    onSwipeLeft: PropTypes.func,
    onSwipeRight: PropTypes.func,
    onDoubleClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    onSwipeLeft: null,
    onSwipeRight: null,
    className: null,
    style: null,
  };

  static eventDelta(start, end) {
    const { clientX: startX, clientY: startY } = start.changedTouches.item(0);
    const { clientX: endX, clientY: endY } = end.changedTouches.item(0);
    return {
      deltaX: endX - startX,
      deltaY: endY - startY,
    };
  }

  static maxSwipeTime = 500;
  static minTotalDeltaSwipe = 60;
  static minSwipeSpeed = 0.5;

  componentWillUnmount() {
    if (this.swipeTimerId) window.clearTimeout(this.swipeTimerId);
  }

  startEvent = null;
  previousTouchEvent = null;
  canceled = false;
  swipeTimerId = null;

  cancel() {
    this.canceled = true;
    if (this.swipeTimerId) window.clearTimeout(this.swipeTimerId);
    this.swipeTimerId = null;
  }

  reset() {
    this.canceled = false;
    this.startEvent = null;
    this.previousTouchEvent = null;
    if (this.swipeTimerId) window.clearTimeout(this.swipeTimerId);
    this.swipeTimerId = null;
  }

  handleTouchStart = (e) => {
    if (e.touches.length > 1) {
      this.cancel();
      return;
    }
    e.persist();
    this.startEvent = e;
    this.previousTouchEvent = e;
    this.swipeTimerId = window.setTimeout(() => {
      this.cancel();
      this.swipeTimerId = null;
    }, TouchSwipe.maxSwipeTime);
  };

  handleTouchMove = (e) => {
    if (this.canceled) return;
    this.checkForSwipe(e);
    e.persist();
    this.previousTouchEvent = e;
  };

  handleTouchEnd = (e) => {
    if (!this.canceled) this.checkForSwipe(e);
    this.cancel();
    if (e.touches.length === 0) this.reset();
  };

  handleTouchCancel = (e) => {
    this.cancel();
    if (e.touches.length === 0) this.reset();
  };

  checkForSwipe(e) {
    if (this.canceled || !this.startEvent) return;
    const { deltaX: totalX, deltaY: totalY } = TouchSwipe.eventDelta(
      this.startEvent,
      e,
    );

    let swipe = null;

    if (Math.abs(totalX) > 2 * Math.abs(totalY)) {
      if (totalX < -TouchSwipe.minTotalDeltaSwipe) swipe = 'left';
      else if (totalX > TouchSwipe.minTotalDeltaSwipe) swipe = 'right';
      else {
        const { deltaX } = TouchSwipe.eventDelta(this.previousTouchEvent, e);
        const deltaTime = e.timeStamp - this.previousTouchEvent.timeStamp;
        if (Math.abs(deltaX / deltaTime) > TouchSwipe.minSwipeSpeed) {
          if (totalX < 0) swipe = 'left';
          else swipe = 'right';
        }
      }
    }

    if (swipe === 'left') this.props.onSwipeLeft && this.props.onSwipeLeft();
    else if (swipe === 'right')
      this.props.onSwipeRight && this.props.onSwipeRight();
    else return;

    // only canceled if swipe occurred
    this.cancel();
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        onTouchCancel={this.handleTouchCancel}
        onDoubleClick={this.props.onDoubleClick}
      >
        {this.props.children}
      </div>
    );
  }
}
