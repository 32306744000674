import { calcImageSize } from './calcImageSize';
import { loadImage, loadedImages } from './loadImage';
import { generateImageUrl } from './generateImageUrl';

// ids of image arrays that have already been preloaded, used outside of this
// file to check before calling preload, and to add to once preload is complete
export const preloadedImageArrayIds = {};

const preloadingIds = {};
let canceled = false;
// function to cancel all preloads in process
export function cancelPreloads() {
  canceled = true;
  Object.keys(preloadingIds).forEach((id) => {
    preloadingIds[id]();
    delete preloadingIds[id];
  });
}

function preloadImage(image, sizeConstraints, onComplete) {
  const { width, height, scaledWidth, scaledHeight, pixelRatio } =
    calcImageSize(image, sizeConstraints);
  const src = generateImageUrl({
    source: image.source,
    width,
    height,
    nativeWidth: image.width,
    nativeHeight: image.height,
  });
  const srcset = generateImageUrl({
    source: image.source,
    width: scaledWidth,
    height: scaledHeight,
    nativeWidth: image.width,
    nativeHeight: image.height,
    pixelRatio,
  });
  if (!loadedImages[src] || !loadedImages[srcset])
    preloadingIds[`${src}${srcset}`] = loadImage({
      src,
      srcset,
      onComplete: () => {
        delete preloadingIds[`${src}${srcset}`];
        onComplete && onComplete();
      },
    });
  else
    window.setTimeout(() => {
      onComplete && onComplete();
    }, 0);
}

function validIndex(index, array) {
  return (
    typeof index === 'number' &&
    index % 1 === 0 &&
    index >= 0 &&
    index < array.length
  );
}

export function preloadImagesInSequence({
  imageArray,
  sizeConstraints,
  startIndex,
  endIndex,
  onComplete,
}) {
  if (!Array.isArray(imageArray) || imageArray.length === 0) {
    onComplete && onComplete();
    return;
  }
  const i = validIndex(startIndex, imageArray) ? startIndex : 0;
  const endIdx = validIndex(endIndex, imageArray)
    ? endIndex
    : imageArray.length - 1;
  canceled = false;
  const onCompleteCallback = () => {
    if (canceled) return;
    if (i === endIndex) onComplete();
    else {
      preloadImagesInSequence({
        imageArray,
        sizeConstraints,
        startIndex: i + 1 === imageArray.length ? 0 : i + 1,
        endIndex: endIdx,
        onComplete,
      });
    }
  };

  Array.isArray(imageArray[i])
    ? preloadImagesInSequence({
        imageArray: imageArray[i],
        sizeConstraints,
        onComplete: onCompleteCallback,
      })
    : window.setTimeout(() => {
        preloadImage(imageArray[i], sizeConstraints, onCompleteCallback);
      }, 0);
}
