import PropTypes from 'prop-types';
import { loadingFontSizeRem } from '../galleryStyle';
import './Loading.css';

export function Loading({ children, style, ...props }) {
  return (
    <div
      className="Loading-container"
      {...props}
      style={{
        ...style,
        fontSize: `${loadingFontSizeRem}rem`,
      }}
    >
      <div className="Loading-text">{children}</div>
    </div>
  );
}

Loading.propTypes = {
  children: PropTypes.node.isRequired,
};
