import * as detectIt from 'detect-it';
import { stickyFooterHeight } from '../galleryStyle';

let maxWidth;
let maxHeight;

if (detectIt.deviceType !== 'mouseOnly') {
  // allow for screen rotation on touch and hybrid devices
  maxWidth = Math.max(window.screen.width, window.screen.height);
  maxHeight =
    Math.max(window.screen.width, window.screen.height) - stickyFooterHeight;
} else {
  maxWidth = window.screen.width;
  maxHeight = window.screen.height - stickyFooterHeight;
}

export const imagePageSizeConstraints = {
  maxWidth,
  maxHeight,
  sizeId: `maxWidth${maxWidth}-maxHeight${maxHeight}`,
};
