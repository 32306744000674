import * as React from 'react';
import PropTypes from 'prop-types';
import { setEventFrom } from 'react-interactive';
import { passiveNoCaptureEventOptions } from '../utils/eventListenerOptions';
import { InteractiveLink } from './InteractiveLink';
import { iconButtonStyle } from '../galleryStyle';
import './IconButton.css';

export const IconButton = ({ triggerKey, disabled, children, ...props }) => {
  const elementRef = React.useRef(null);

  const handleKeyDown = React.useCallback(
    (e) => {
      // simulate hover
      if (e.key === triggerKey && !disabled) {
        if (elementRef.current) {
          setEventFrom('key');
          const mouseoverEvent = new Event('mouseover', { bubbles: true });
          elementRef.current.dispatchEvent(mouseoverEvent);
        }
        window.setTimeout(() => {
          if (elementRef.current) {
            elementRef.current.click();
          }
        }, 50);
      }
    },
    [triggerKey, disabled],
  );

  const handleKeyUp = React.useCallback(
    (e) => {
      if (e.key === triggerKey && elementRef.current && !disabled) {
        setEventFrom('mouse');
        const mouseoutEvent = new Event('mouseout', { bubbles: true });
        elementRef.current.dispatchEvent(mouseoutEvent);
      }
    },
    [triggerKey, disabled],
  );

  React.useEffect(() => {
    document.addEventListener(
      'keydown',
      handleKeyDown,
      passiveNoCaptureEventOptions,
    );
    document.addEventListener(
      'keyup',
      handleKeyUp,
      passiveNoCaptureEventOptions,
    );

    return () => {
      document.removeEventListener(
        'keydown',
        handleKeyDown,
        passiveNoCaptureEventOptions,
      );
      document.removeEventListener(
        'keyup',
        handleKeyUp,
        passiveNoCaptureEventOptions,
      );
    };
  }, [handleKeyDown, handleKeyUp]);

  return (
    <InteractiveLink
      {...iconButtonStyle}
      // allow props to override default iconButtonStyle
      // used by Info button to override focusFromKeyStyle
      {...props}
      disabled={disabled}
      className="IconButton"
      ref={elementRef}
    >
      {children}
    </InteractiveLink>
  );
};

IconButton.propTypes = {
  triggerKey: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any.isRequired,
};
