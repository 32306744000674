import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

export const routes = {
  root: 'root',
  portfolio: 'portfolio',
  image: 'image',
  notFound: 'notFound',
};

export function RouteManagement(props) {
  const { children } = props;
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(router) => children({ routeName: routes.root, router })}
      />
      <Route
        exact
        path="/:portfolio"
        render={(router) => children({ routeName: routes.portfolio, router })}
      />
      <Route
        exact
        path="/:portfolio/:image"
        render={(router) => children({ routeName: routes.image, router })}
      />
      <Route
        render={(router) => children({ routeName: routes.notFound, router })}
      />
    </Switch>
  );
}

RouteManagement.propTypes = {
  children: PropTypes.func.isRequired,
};
