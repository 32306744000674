export const pageNotFound = {
  message: 'Page Not Found',
  linkTo: '/',
  linkText: 'Back to Home',
};

export const portfolioNotFound = {
  message: 'Portfolio Not Found',
  linkTo: '/',
  linkText: 'Back to Home',
};

export function imageNotFound(portfolio) {
  return {
    message: 'Image Not Found',
    linkTo: `/${portfolio.urlTitle}`,
    linkText: `Back to ${portfolio.title} Portfolio`,
  };
}
