export const viewNames = {
  GALLERY_LOADING: 'GALLERY_LOADING',
  NO_USER_ERROR: 'NO_USER_ERROR',
  //-------------------------------
  HOMEPAGE_WIDE_IMAGES: 'HOMEPAGE_WIDE_IMAGES',
  HOMEPAGE_WIDE_MENU: 'HOMEPAGE_WIDE_MENU',
  HOMEPAGE_WIDE_INFO: 'HOMEPAGE_WIDE_INFO',
  HOMEPAGE_NARROW_IMAGES: 'HOMEPAGE_NARROW_IMAGES',
  HOMEPAGE_NARROW_MENU: 'HOMEPAGE_NARROW_MENU',
  HOMEPAGE_NARROW_INFO: 'HOMEPAGE_NARROW_INFO',
  //-------------------------------
  PORTFOLIO_WIDE_IMAGES: 'PORTFOLIO_WIDE_IMAGES',
  PORTFOLIO_WIDE_MENU: 'PORTFOLIO_WIDE_MENU',
  PORTFOLIO_WIDE_INFO: 'PORTFOLIO_WIDE_INFO',
  PORTFOLIO_WIDE_REQUEST_FULLSCREEN: 'PORTFOLIO_WIDE_REQUEST_FULLSCREEN',
  PORTFOLIO_NARROW_IMAGES: 'PORTFOLIO_NARROW_IMAGES',
  PORTFOLIO_NARROW_MENU: 'PORTFOLIO_NARROW_MENU',
  PORTFOLIO_NARROW_INFO: 'PORTFOLIO_NARROW_INFO',
  PORTFOLIO_NARROW_REQUEST_FULLSCREEN: 'PORTFOLIO_NARROW_REQUEST_FULLSCREEN',
  //-------------------------------
  IMAGE_PAGE: 'IMAGE_PAGE',
};
